<template>
    <Card :title="(description ? description+'-' :'')+$t(isDateStringFuture(licensedUntil) ? 'license.valid' : 'license.expired')" is-dark mode="default">
        <InfoBox :mode="isDateStringFuture(licensedUntil) ? 'success' : 'danger'"
        :icon="isDateStringFuture(licensedUntil) ? 'check' : 'warning'">
            <div v-if="isDateStringFuture(licensedUntil)">{{
                    $t('license.message', {
                        duration: getDaysLeftByDateString(licensedUntil),
                        datetime: getFormattedDateTimeByDateString(licensedUntil),
                        description: description ? description+'-' : '',
                    })
                }}
            </div>
            <div v-else>{{ $t('license.expired.at.message', {
                duration: getDaysLeftByDateString(licensedUntil, false),
                datetime: getFormattedDateTimeByDateString(licensedUntil),
                description: description ? description+'-' : '',
            }) }}</div>
        </InfoBox>

        <span v-if="licenseLink">
            <div class="uk-margin-medium-bottom"/>
            <a target="_blank" :href="licenseLink">
                <Button :text="$t('renew.license')" is-large is-max mode="primary"/>
            </a>
        </span>
    </Card>
</template>

<script>

import Button from "@/components/generic/Button.vue";
import Card from "@/components/generic/Card.vue";
import InfoBox from "@/components/generic/InfoBox.vue";

export default {
    name: 'License',
    components: {InfoBox, Card, Button},
    props: {
        title: String,
        licensedUntil: String,
        licenseLink:String,
        description: String
    }
}

</script>

<style lang="scss" scoped>


</style>
