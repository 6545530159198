<template>
    <div class="site-struct single">

        <LoadingStruct is-dark :is-loading="false">
            <Card :title="$t('admin.area')" is-dark mode="default">

                <span v-for="(inputField, key) in inputFields" :key="inputField.name">
                    <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                               :autofocus="inputField.autofocus" :field-key="key"
                               :hasPasswordMeter="inputField.hasPasswordMeter"
                               :icon="inputField.icon"
                               :is-optional="inputField.optional"
                               :label="inputField.label"
                               :name="inputField.name"
                               :on-enter="login"
                               :rules="inputField.rules"
                               :type="inputField.type"
                               :validate="trigger"
                               @setValid="setValid"
                    />
                    <div v-if="key < inputFields.length-1" class="uk-margin-bottom"/>
                </span>

                <div class="forgot-struct"/>
                <LinkObject to="reset-password">
                    <span class="forgot">
                        {{ $t('forgot.password') }}
                    </span>
                </LinkObject>

                <div class="uk-margin-medium-bottom"/>
                <Button :action="login" :is-loading="loadingLogin" :text="$t('login')" has-action is-large is-max
                        mode="primary"/>

                <div v-if="errorLogin" class="uk-margin-medium-bottom"/>
                <ErrorMessage :custom-errors="[{code: 401, message: $t('invalid.credentials')}]" :error="errorLogin"/>
            </Card>
        </LoadingStruct>

    </div>
</template>

<script>

import LoadingStruct from "@/views/LoadingStruct";
import Card from "@/components/generic/Card";
import TextField from "@/components/generic/TextField";
import Button from "@/components/generic/Button";
import axios from "axios";
import ErrorMessage from "@/components/generic/ErrorMessage";
import VueCookie from "vue-cookie";
import LinkObject from "@/components/LinkObject";

export default {
    name: 'Login',
    components: {
        LinkObject,
        ErrorMessage,
        Button,
        TextField,
        Card,
        LoadingStruct,
    },
    data() {
        return {
            trigger: 0,
            inputFields: [
                {
                    value: "",
                    valid: false,

                    name: "email",
                    label: "emailAddress",
                    icon: "mail",
                    rules: "required|email",
                },
                {
                    value: "",
                    valid: false,

                    name: "password",
                    label: "password",
                    icon: "lock",
                    rules: "required",
                    autocomplete: "password",
                    type: "password",
                },
            ],

            errorLogin: null,
            loadingLogin: false,
        }
    },
    methods: {
        setValid(valid, fieldKey) {

            if (valid && fieldKey === 0) {
                this.$store.state.emailReset = this.inputFields[fieldKey].value;
            }

            this.inputFields[fieldKey].valid = valid
        },
        login() {
            this.trigger++;

            for (let i = 0, j = this.inputFields.length; i < j; i++) {
                if (!this.inputFields[i].valid) {
                    return;
                }
            }

            this.loadingLogin = true;
            let http = axios.create();
            http.post(this.apiUrl + '/authenticate', {
                "email": this.inputFields[0].value,
                "password": this.inputFields[1].value,

            }).then(response => {
                this.$store.state.token = response.data.token;
                VueCookie.set('token', response.data.token, 60 * 60 * 24 * 7)
                axios.defaults.headers.common["token"] = response.data.token;

                this.errorLogin = null;
                this.loadingLogin = false;

                this.routeTo("admin");

            }).catch(e => {
                this.checkGenericError(e);
                this.errorLogin = e;
                this.loadingLogin = false;

            });

        }
    }
}

</script>

<style lang="scss" scoped>

.forgot-struct {
    margin-top: 5px;
}

.forgot {
    color: #000 !important;
    font-size: 14px;
}

</style>
