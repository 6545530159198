import axios from "axios";
import {i18n} from '@/main'

const apiUrl = process.env.VUE_APP_API_URL || '/api';

export default function setup_uncompleted({next, router}) {
    let http = axios.create();
    http.get(apiUrl + '/galleries').then(() => {
        return next();

    }).catch(() => {
        return router.push('/' + i18n.locale + '/');

    });
}
