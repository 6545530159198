<template>
    <div :class="answered ? 'uk-animation-reverse uk-animation-fade uk-hidden' : (show ? '' : 'uk-hidden')">

        <div v-if="isPromptInstaller" class="prompt-struct uk-text-center">
            <Card is-dark mode="default" bodyClass="slim-padding">
                <div class="uk-grid-small uk-width-auto uk-flex uk-flex-center uk-flex-middle" uk-grid>
                    <div>
                        <span v-on:click="deny()"><Icon icon="close" :ratio="1.2"
                                                        class="uk-margin-small-right cursor-pointer"/></span>
                        <img src="../../public/img/icons/android-chrome-512x512.png" class="logo uk-margin-small-right"
                             uk-img/>
                        <span class="name">{{ name }}</span>
                    </div>
                    <div>
                        <Button has-action :action="promptInstaller" mode="primary">{{ $t('install') }}</Button>
                    </div>
                </div>

            </Card>
        </div>

    </div>
</template>


<script>

import Button from "@/components/generic/Button";
import Card from "@/components/generic/Card.vue";
import Icon from "@/components/generic/Icon.vue";

export default {
    name: 'PWAPrompt',
    components: {Icon, Card, Button},
    props: {
        name: String,
    },
    data() {
        return {
            show: false,
            answered: false,
            cookieKey: 'pwa',

            promptInstallerEvent: null,
            isPromptInstaller: false,
        }
    },
    created() {
        let optOut = this.$cookie.get(this.cookieKey);
        if (optOut) {
            this.show = false;
            this.$store.state.pwa = optOut;
        } else {
            this.show = true;
        }
    },
    mounted() {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.promptInstallerEvent = e;
            this.isPromptInstaller = true;
        });
    },
    methods: {
        promptInstaller() {
            this.promptInstallerEvent.prompt()
            this.promptInstallerEvent.userChoice.then((choice) => {
                if (choice.outcome === 'accepted') {
                    this.accept();
                }
            })
        },
        accept() {
            this.answered = true;
            this.$store.state.pwa = "accepted";
            this.$cookie.set(this.cookieKey, "accepted", {expires: this.cookieMaxExpiry});
        },
        deny() {
            this.answered = true;
            this.$store.state.pwa = "denied";
            this.$cookie.set(this.cookieKey, "denied", {expires: this.cookieMaxExpiry});
        }
    }
}

</script>


<style lang="scss" scoped>

.logo {
    height: 50px;
}

.name {
    font-weight: 500;
    font-size: $site-font-size-large;
}

</style>
