import Vue from 'vue';
import {i18n} from '@/main';
import VueRouter from 'vue-router';
import Router from '@/router/Router';

// import Main from "@/views/Main";
import Admin from '@/views/Admin';
import Setup from '@/views/Setup';
import ResetPassword from '@/views/ResetPassword';

import setupCompletedMiddleware from '@/middlewares/setupCompleted';
import setupUncompletedMiddleware from '@/middlewares/setupUncompleted';
import authMiddleware from '@/middlewares/auth';
import galleriesMiddleware from '@/middlewares/galleries';
import Login from '@/views/Login';
import ResetPasswordConfirm from '@/views/ResetPasswordConfirm';
import Lang from '@/views/Lang.vue';
import Gallery from '@/views/Gallery.vue';
import Galleries from '@/views/Galleries.vue';
import Start from '@/views/Start.vue';
import Transfer from "../views/Transfer.vue";

Vue.use(VueRouter);

const navigatorLanguages = [
    {
        key: 'de',
        codes: ['de', 'de-*'],
    },
    {
        key: 'ch',
        codes: ['ch', 'ch-*'],
    },
    {
        key: 'at',
        codes: ['at', 'at-*'],
    },
    {
        key: 'li',
        codes: ['li', 'li-*'],
    },
    {
        key: 'en',
        codes: ['en', 'en-*'],
    },
    {
        key: 'it',
        codes: ['it', 'it-*'],
    },
    {
        key: 'fr',
        codes: ['fr', 'fr-*'],
    },
    {
        key: 'nl',
        codes: ['nl', 'nl-*'],
    },
    {
        key: 'fi',
        codes: ['fi', 'fi-*'],
    },
];

const allowedLanguages = ['de', 'ch', 'at', 'li', 'en', 'it', 'fr', 'nl', 'fi'];

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

const routes = [
    {
        path: '/:lang',
        component: Router,
        beforeEnter(to, from, next) {
            let lang = to.params.lang;
            let target = lang;

            lang = Vue.localStorage.get('language');

            if (!allowedLanguages.includes(lang)) {
                for (let i = 0, j = navigatorLanguages.length; i < j; i++) {
                    let navigatorLanguage = navigator.language;
                    navigatorLanguage = navigatorLanguage.toLowerCase();

                    let wildcard = navigatorLanguage.substring(0, 3) + '*';

                    if (
                        navigatorLanguages[i].codes.includes(
                            navigatorLanguage
                        ) ||
                        navigatorLanguages[i].codes.includes(wildcard)
                    ) {
                        lang = navigatorLanguages[i].key;
                        break;
                    }
                }
            }

            if (!allowedLanguages.includes(lang)) {
                lang = 'de';
            }

            if (i18n.locale !== lang) {
                i18n.locale = lang;
            }

            Vue.localStorage.set('language', lang);

            if (!allowedLanguages.includes(target)) {
                router.push('/' + lang + '/' + target);
            }

            return next();
        },
        children: [
            {
                path: '',
                name: 'start',
                component: Start,
                meta: {
                    middleware: setupCompletedMiddleware,
                },
            },
            {
                path: 'admin',
                name: 'admin',
                component: Admin,
                meta: {
                    middleware: [setupCompletedMiddleware, authMiddleware],
                },
            },
            {
                path: 'login',
                name: 'login',
                component: Login,
                meta: {
                    middleware: setupCompletedMiddleware,
                },
            },
            {
                path: 'setup',
                name: 'setup',
                component: Setup,
                meta: {
                    middleware: setupUncompletedMiddleware,
                },
            },
            {
                path: 'reset-password',
                name: 'reset-password',
                component: ResetPassword,
                meta: {
                    middleware: setupCompletedMiddleware,
                },
            },
            {
                path: 'reset-password-confirm',
                name: 'reset-password-confirm',
                component: ResetPasswordConfirm,
                meta: {
                    middleware: setupCompletedMiddleware,
                },
            },

            {
                path: 'galleries',
                name: 'galleries',
                component: Galleries,
                meta: {
                    middleware: [setupCompletedMiddleware, galleriesMiddleware],
                },
            },
            {
                path: 'galleries/:gallery',
                name: 'gallery',
                component: Gallery,
                meta: {
                    middleware: [setupCompletedMiddleware, galleriesMiddleware],
                },
            },
            {
                path: 'transfers/:transfer',
                name: 'transfer',
                component: Transfer,
                meta: {
                    middleware: [setupCompletedMiddleware],
                },
            },

            /*
            {
                path: 'upload',
                name: 'upload',
                component: Main,
                meta: {
                    middleware: [setupCompletedMiddleware],
                },
            },
             */
        ],
    },
    {
        path: '*',
        name: 'lang',
        component: Lang,
        meta: {},
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
});

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({...context, next: nextMiddleware});
    }

    return next();
});

export default router;
