<template>
    <div>

        <div class="uk-position-relative"
             :id="slideshowId"
             uk-slideshow="animation: fade; draggable: false; pause-on-hover: false;">
            <ul class="uk-slideshow-items">
                <li v-for="ad in terminal.ads" :key="ad">
                    <video v-if="isVideo(ad)"
                           muted
                           oncontextmenu="return false;"
                           playsinline
                           uk-cover>
                        <source :src="apiUrl+'/terminal-ads/'+ad"
                                :type="'video/'+getVideoType(ad)">
                    </video>
                    <img v-else :src="apiUrl+'/terminal-ads/'+ad"
                         uk-cover/>
                </li>
            </ul>
        </div>

    </div>
</template>


<script>

export default {
    name: 'TerminalAdsFooter',
    props: {
        terminal: {
            screensavers: Array,
            screensaversText: String,
            sliderInterval: Number,
            sliderVideoAdjusted: Boolean,
            adsEnabled: Boolean,
            ads: Array,
        },
    },
    data() {
        return {
            slideshowId: "slideshow-terminal-ads",
        }
    },
}

</script>


<style lang="scss" scoped>

</style>
