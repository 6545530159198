<template>
    <span>

        <div class="uk-grid-small uk-flex uk-flex-middle" uk-grid>
            <div class="uk-width-expand">
                <LabelWithError v-if="label" :class="(isLabelHidden ? ' uk-hidden' : '')"
                                :error="errors.first(name)"
                                :is-label-large="isLabelLarge"
                                :label="$t(label) +labelInfo+ (rules && rules.includes('required') ? '*' : ' ('+$t('optional')+')')"/>
            </div>
            <div v-if="$slots.besidesLabel" class="besides-label">
                <slot name="besidesLabel"/>
            </div>
        </div>

        <div :class="'uk-inline uk-width-1-1' + (isHidden ? ' uk-hidden' : '')">
            <span v-if="icon" :uk-icon="'icon: '+icon" class="uk-form-icon"/>
            <input ref="inputRef"
                   v-validate="rules"
                   :class="'uk-input'+(isStyled ? '-styled' : '')+(isDisabled ? ' cursor-notallowed' : '')"
                   :data-vv-as="$t(label)"
                   :name="name"
                   type="text"
                   :placeholder="placeholder"
                   v-on:change="onChange"
                   v-on:keyup.enter="onEnter"
                   :disabled="isDisabled"
            />
        </div>
    </span>

</template>

<script>
import {watch} from '@vue/composition-api' // Vue 2: import { watch } from '@vue/composition-api'
import {useCurrencyInput} from 'vue-currency-input'
import LabelWithError from "@/components/generic/LabelWithError";

export default {
    name: 'CurrencyInput',
    components: {LabelWithError},
    props: {
        value: Number,
        options: Object,
        isStyled: Boolean,

        label: String,
        labelInfo: {
            type: String,
            default: ''
        },
        isLabelHidden: Boolean,
        isOptional: Boolean,
        isLabelLarge: Boolean,
        rules: String,
        name: String,
        icon: String,
        isHidden: Boolean,
        onChange: {
            type: Function,
            default: function () {
            },
        },
        onEnter: {
            type: Function,
            default: function () {
            },
        },
        placeholder: String,
        isDisabled: Boolean,
    },
    setup(props) {
        const {inputRef, setOptions, setValue} = useCurrencyInput(props.options)

        watch(
            () => props.value, // Vue 2: props.value
            (value) => {
                if (value === null) {
                    value = 0;
                }

                setValue(value)
            }
        )

        watch(
            () => props.options,
            (options) => {
                setOptions(options)
            }
        )

        return {inputRef}
    },
    watch: {
        validate() {
            this.validation();
        },
    },
    methods: {
        validation() {
            this.$validator.validate().then(valid => {
                if (!valid && this.autofocus) {
                    this.$refs.inputRef.focus();
                }

                this.$emit('setValid', valid, this.fieldKey, this.identifier)
            });
        },
        updateValue(value) {
            if (this.type === 'number') {
                value = parseFloat(value);
            }

            this.$emit('input', value, this.fieldKey)
            this.validation();

            let self = this;
            setTimeout(function () {
                self.onInput();
            }, 50);
        },
    },
}
</script>


<style lang="scss" scoped>

.uk-input {
    border-radius: $border-radius;
    border-color: $border-color !important;
    height: 50px;
    color: $site-background-hover !important;
    width: 100%;
    font-weight: 500;
    font-size: 16px;

    &:hover, &:focus {
        border-color: $info-color !important;
    }

    &.dark {
        &:hover, &:focus {
            border-color: $theme-color-hover !important;
        }
    }

    &.danger {
        background: rgba(240, 80, 80, .15);
        border-color: $theme-background !important;
    }

    &.simple {
        border-radius: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }
}

.besides-label {
    margin-bottom: 8px;
}

</style>
