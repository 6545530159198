<template>
    <span>
        {{ $t(translationKey, parameter) }}
    </span>
</template>


<script>

export default {
    name: 'Translate',
    props: {
        translationKey: String,
        parameter:Object,
    }
}

</script>


<style lang="scss" scoped>

</style>
