<template>
    <span
        :uk-tooltip="disabled ? 'title:'+$t('x.module.disabled.hint', {x:$t('terminal')}) : ''"
        :class="disabled ? 'module-disabled-struct' : ''">

    <span :class="disabled ? 'module-disabled uk-height-1-1' : 'uk-height-1-1'">

     <Card :title="$t(title)" is-dark mode="default" id="smtp-config" class="uk-height-1-1">
         <Description v-if="description" :text="$t(description)"/>

         <InfoBox v-if="!smtpConfig.valid"
                  icon="warning"
                  class="uk-margin-medium-bottom"
                  mode="danger">
             {{ $t('smtp.config.invalid.hint') }}
         </InfoBox>

         <span v-for="(inputField, key) in inputFields" :key="inputField.name">
             <TextField
                 v-model="inputField.value"
                 :autocomplete="inputField.autocomplete"
                 :autofocus="inputField.autofocus"
                 :field-key="key"
                 :hasPasswordMeter="inputField.hasPasswordMeter"
                 :icon="inputField.icon"
                 :is-optional="inputField.optional"
                 :label="inputField.label"
                 :name="inputField.name"
                 :options="inputField.options"
                 :rules="inputField.rules"
                 :steps="inputField.steps"
                 :type="inputField.type"

                 :on-enter="actionUpdate"
                 :validate="validationTrigger"
                 @setValid="setValid"
             />

             <div v-if="key < inputFields.length-1" class="uk-margin-bottom"/>
         </span>

         <div class="uk-margin-medium-bottom"/>
         <Button
             :action="actionUpdate"
             :is-loading="loading"
             :text="$t('x.save', {x: $t(title)})"
             has-action
             is-large
             is-max
             mode="primary"
         />

         <div v-if="error" class="uk-margin-medium-bottom"/>
         <ErrorMessage :error="error" :custom-errors="[{code: 400, message: $t('smtp.config.invalid')}]"/>

         <div v-if="success" class="uk-margin-medium-bottom"/>
         <SuccessMessage :success="success" :message="$t('x.changes.saved', {x: $t(title)})"/>


         <span v-if="smtpConfig.set || success">
             <div class="uk-margin-top"/>
             <hr/>
         <div class="uk-margin-bottom"/>
         <Button
             :is-disabled="successDelete"
             :action="actionDelete"
             :is-loading="loadingDelete"
             :text="$t('x.delete', {x: $t(title)})"
             has-action
             is-large
             is-max
             mode="default"
         />

         <div v-if="errorDelete" class="uk-margin-medium-bottom"/>
         <ErrorMessage :error="errorDelete"/>

         <div v-if="successDelete" class="uk-margin-medium-bottom"/>
         <SuccessMessage :success="successDelete" :message="$t('x.deleted', {x: $t(title)})"/>
</span>


     </Card>

    </span>
                </span>
</template>

<script>

import SuccessMessage from "@/components/generic/SuccessMessage.vue";
import Description from "@/components/Description.vue";
import Button from "@/components/generic/Button.vue";
import TextField from "@/components/generic/TextField.vue";
import Card from "@/components/generic/Card.vue";
import ErrorMessage from "@/components/generic/ErrorMessage.vue";
import axios from "axios";
import InfoBox from "@/components/generic/InfoBox.vue";

export default {
    name: 'SMTPConfigForm',
    components: {InfoBox, ErrorMessage, Card, TextField, Button, Description, SuccessMessage},
    props: {
        smtpConfig: {
            host: String,
            port: String,
            email: String,
            user: String,
            valid: Boolean,
            set: Boolean,
        },
        disabled:Boolean,
    },
    mounted() {
        if (this.smtpConfig.email) {
            this.inputFields[0].value = this.smtpConfig.email;
            this.inputFields[0].valid = true;
        }

        if (this.smtpConfig.host) {
            this.inputFields[1].value = this.smtpConfig.host;
            this.inputFields[1].valid = true;
        }

        if (this.smtpConfig.port) {
            this.inputFields[2].value = this.smtpConfig.port;
            this.inputFields[2].valid = true;
        }

        if (this.smtpConfig.user) {
            this.inputFields[3].value = this.smtpConfig.user;
            this.inputFields[3].valid = true;
        }
    },
    data() {
        return {
            title: "smtp.config",
            description: "smtp.config.message",

            loading: false,
            success: false,
            error: null,

            loadingDelete: false,
            successDelete: false,
            errorDelete: null,

            validationTrigger: 0,
            inputFields: [
                {
                    value: "",
                    valid: false,

                    name: "smtp-email",
                    label: "emailAddress",
                    icon: "mail",
                    rules: "required|emailV2",
                },
                {
                    value: "",
                    valid: false,

                    name: "smtp-host",
                    label: "server.host",
                    icon: "server",
                    rules: "required",
                },
                {
                    value: "",
                    valid: false,

                    name: "smtp-port",
                    label: "server.port",
                    icon: "link",
                    rules: "required|numeric",
                },
                {
                    value: "",
                    valid: false,

                    name: "smtp-user",
                    label: "email.user",
                    icon: "user",
                    rules: "required",
                },
                {
                    value: "",
                    valid: false,

                    name: "smtp-password",
                    label: "password",
                    icon: "lock",
                    rules: "required",
                    type: "password",
                },
            ],
        }
    },
    methods: {
        setValid(valid, fieldKey) {
            this.success = false;
            this.inputFields[fieldKey].valid = valid
        },
        actionUpdate() {
            this.validationTrigger++;

            for (let i = 0, j = this.inputFields.length; i < j; i++) {
                if (!this.inputFields[i].valid) {
                    return;
                }
            }

            this.loading = true;

            let http = axios.create();
            http.put(this.apiUrl + '/smtp-config', {
                email: this.inputFields[0].value,
                host: this.inputFields[1].value,
                port: parseInt(this.inputFields[2].value),
                user: this.inputFields[3].value,
                password: this.inputFields[4].value,

            }).then(() => {
                this.error = null;
                this.success = true;
                this.successDelete= false;
                this.loading = false;

                this.$emit('updated');

            }).catch(e => {
                this.checkGenericError(e);

                this.error = e;
                this.success = false;
                this.loading = false;

            });
        },
        actionDelete() {
            this.loadingDelete = true;

            let http = axios.create();
            http.delete(this.apiUrl + '/smtp-config').then(() => {
                for (let i = 0, j = this.inputFields.length; i < j; i++) {
                    this.inputFields[i].value = "";
                    this.inputFields[i].valid = false;
                }

                this.errorDelete = null;
                this.successDelete = true;
                this.success= false;
                this.loadingDelete = false;

            }).catch(e => {
                this.checkGenericError(e);

                this.errorDelete = e;
                this.successDelete = false;
                this.loadingDelete = false;

            });
        },
    }
}

</script>


<style lang="scss" scoped>

</style>
