import VueCookie from 'vue-cookie'
import store from '@/store'
import {i18n} from '@/main'

export default function auth({next, router}) {
    if (!store.state.token && !VueCookie.get('token')) {
        return router.push('/' + i18n.locale + '/login');
    }

    return next();
}
