<template>
    <div>
    </div>
</template>

<script>

const navigatorLanguages = [
    {
        "key": "de",
        "codes": ["de", "de-*",],
    },
    {
        "key": "ch",
        "codes": ["ch", "ch-*",],
    },
    {
        "key": "at",
        "codes": ["at", "at-*",],
    },
    {
        "key": "li",
        "codes": ["li", "li-*",],
    },
    {
        "key": "en",
        "codes": ["en", "en-*",],
    },
    {
        "key": "it",
        "codes": ["it", "it-*",],
    },
    {
        "key": "fr",
        "codes": ["fr", "fr-*",],
    },
    {
        "key": "nl",
        "codes": ["nl", "nl-*",],
    },
    {
        "key": "fi",
        "codes": ["fi", "fi-*",],
    },
];

const allowedLanguages = ['de', 'ch', 'at', 'li', 'en', 'it', 'fr', 'nl', 'fi'];

import Vue from 'vue'

export default {
    name: 'Lang',
    created() {
        let lang = Vue.localStorage.get('language');

        if (!allowedLanguages.includes(lang)) {
            for (let i = 0, j = navigatorLanguages.length; i < j; i++) {
                let navigatorLanguage = navigator.language;
                navigatorLanguage = navigatorLanguage.toLowerCase()

                let wildcard = navigatorLanguage.substring(0, 3) + "*"

                if (navigatorLanguages[i].codes.includes(navigatorLanguage)
                    || navigatorLanguages[i].codes.includes(wildcard)) {
                    lang = navigatorLanguages[i].key;
                    break;
                }
            }

            if (!lang) {
                lang = "de";
            }
        }


        this.$i18n.locale = lang;
        Vue.localStorage.set('language', lang);

        this.$router.push("/" + lang);
    },
}

</script>

<style lang="scss" scoped>
</style>
