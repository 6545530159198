import { render, staticRenderFns } from "./LanguageSelector.vue?vue&type=template&id=48e36579&scoped=true"
import script from "./LanguageSelector.vue?vue&type=script&lang=js"
export * from "./LanguageSelector.vue?vue&type=script&lang=js"
import style0 from "./LanguageSelector.vue?vue&type=style&index=0&id=48e36579&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48e36579",
  null
  
)

export default component.exports