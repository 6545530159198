<template>
    <div>

        <div class="description">
            <span v-html="text"/>
            <slot/>
        </div>

    </div>
</template>

<script>

export default {
    name: 'Description',
    props: {
        text: String,
    }
}

</script>

<style lang="scss" scoped>

.description {
    margin-top: -20px;
    margin-bottom: 40px;
    word-wrap: break-word;
}

</style>
