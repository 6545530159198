<template>
    <div :class="answered ? 'uk-animation-reverse uk-animation-fade uk-hidden' : (show ? '' : 'uk-hidden')">

        <div v-if="isIOSInstaller" class="pwa-struct uk-text-center">
            <Card is-dark mode="default" bodyClass="slim-padding-alt">
                <div class="uk-grid-small uk-width-auto uk-flex uk-flex-center uk-flex-middle" uk-grid>
                    <div>
                        <span v-on:click="deny()"><Icon icon="close" :ratio="1.2"
                                                        class="uk-margin-small-right cursor-pointer"/></span>
                        <img src="../../public/img/icons/android-chrome-512x512.png" class="logo uk-margin-small-right"
                             uk-img/>
                        <span class="name">{{ name }}</span>
                    </div>
                    <div>
                        App installieren mit
                        <Icon icon="push"/>
                        und "Zum Home-Bildschirm" wählen
                    </div>
                </div>

                <Icon icon="arrow-down"/>

            </Card>
        </div>

    </div>
</template>


<script>

import Card from "@/components/generic/Card.vue";
import Icon from "@/components/generic/Icon.vue";

export default {
    name: 'PWAHint',
    components: {Icon, Card},
    props: {
        name: String,
    },
    data() {
        return {
            show: false,
            answered: false,
            cookieKey: 'pwa',

            isIOSInstaller: false,
        }
    },
    created() {
        let optOut = this.$cookie.get(this.cookieKey);
        if (optOut) {
            this.show = false;
            this.$store.state.pwa = optOut;
        } else {
            this.show = true;
        }
    },
    mounted() {
        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

        if (iOSSafari) {
            if (!navigator.standalone) {
                this.isIOSInstaller = true;
            } else {
                this.accept();
            }
        }

        // this.isIOSInstaller = true;
    },
    methods: {
        accept() {
            this.answered = true;
            this.$store.state.pwa = "accepted";
            this.$cookie.set(this.cookieKey, "accepted", {expires: this.cookieMaxExpiry});
        },
        deny() {
            this.answered = true;
            this.$store.state.pwa = "denied";
            this.$cookie.set(this.cookieKey, "denied", {expires: this.cookieMaxExpiry});
        }
    }
}

</script>


<style lang="scss" scoped>

.logo {
    height: 50px;
}

.name {
    font-weight: 500;
    font-size: $site-font-size-large;
}

</style>
