<template>
    <div>
        <CountryFlag
            :country="$i18n.locale === 'en' ? 'us' : $i18n.locale"
            class="cursor-pointer"
            :uk-toggle="'target: #' + modalId"
            size="normal" />

        <Modal is-medium :id="modalId" :title="$t('language')">
            <div
                class="uk-child-width-1-3 uk-text-center uk-grid-small"
                uk-grid>
                <div v-for="language in this.languages" :key="language">
                    <div
                        v-on:click="switchLanguage(language)"
                        class="uk-card uk-card-default uk-card-body uk-card-hover cursor-pointer uk-modal-close">
                        <div
                            class="uk-flex uk-flex-middle uk-flex-center uk-grid-small"
                            uk-grid>
                            <div>
                                <CountryFlag
                                    :country="
                                        language === 'en' ? 'us' : language
                                    "
                                    size="normal" />
                            </div>
                            <div class="language-name">
                                {{ $t(language, language) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

        <div
            v-if="false"
            id="languages"
            class="uk-navbar-dropdown"
            uk-dropdown="mode: click">
            <ul class="uk-nav uk-navbar-dropdown-nav admin-struct">
                <li v-for="(language, key) in this.languages" :key="language">
                    <label class="cursor-pointer">
                        <div uk-grid>
                            <div
                                class="uk-width-expand language uk-flex uk-flex-middle">
                                <CountryFlag
                                    :country="
                                        language === 'en' ? 'us' : language
                                    "
                                    size="normal" />
                                <span class="uk-margin-small-left">
                                    {{ $t(language, language) }}
                                </span>
                            </div>
                            <div class="uk-flex uk-flex-middle">
                                <input
                                    v-model="$i18n.locale"
                                    :name="'language-' + id"
                                    :value="language"
                                    class="uk-radio"
                                    type="radio"
                                    uk-toggle="target: #languages"
                                    v-on:click="switchLanguage(language)" />
                            </div>
                        </div>
                    </label>

                    <hr v-if="key !== languages.length - 1" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import CountryFlag from 'vue-country-flag';
import Modal from '@/components/generic/Modal.vue';

export default {
    name: 'LanguageSelector',
    components: {Modal, CountryFlag},
    props: {
        id: String,
    },
    data() {
        return {
            modalId: 'modal-language-' + this.id,
        };
    },
};
</script>

<style lang="scss" scoped>
.language-name {
    font-size: $site-font-size;
}
</style>
