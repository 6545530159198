<template>
    <span>

        <Modal :id="modalId" :title="$t('terminal.control.panel')" is-close-outside is-medium>

            <div class="uk-margin-medium-top"/>

            <div v-if="!isTerminalConnectorRunning">
                <TerminalConnnectorNotRunnning/>
            </div>

            <div class="uk-animation-fade" v-else-if="authenticated">
                 <div class="uk-width-expand" v-if="actions === null">
                    <div class="uk-flex uk-flex-center uk-flex-middle">
                        <div uk-spinner/>
                    </div>
                </div>
                <div v-else class="uk-grid-small uk-child-width-1-3 uk-flex-center" uk-grid>
                    <div v-for="action in actions" :key="action">
                        <span v-on:click="prepareAction(action)">
                            <Card is-dark
                                  class="uk-text-center uk-card-hover cursor-pointer"
                                  mode="default">
                                <Icon :ratio="2" :icon="getIconByName(action)"/>
                                <div class="action-name uk-margin-small-top">
                                    <Translate :translation-key="action"/>
                                </div>
                            </Card>
                        </span>
                    </div>
                </div>
            </div>

            <div v-else>
                <form onsubmit="return false">
                <input value="Terminal PIN" name="terminal-pin" type="text" class="uk-hidden">
                <span v-for="(inputField, key) in inputFields" :key="inputField.name">
                    <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                               :autofocus="inputField.autofocus" :field-key="key"
                               :hasPasswordMeter="inputField.hasPasswordMeter"
                               :icon="inputField.icon"
                               :is-optional="inputField.optional"
                               :label="inputField.label"
                               :name="inputField.name"
                               :on-enter="login"
                               :rules="inputField.rules"
                               :type="inputField.type"
                               :validate="trigger"

                               @setValid="setValid"
                               has-keyboard
                               has-keyboard-numpad
                    />
                    <div v-if="key < inputFields.length-1" class="uk-margin-bottom"/>
                </span>
                    </form>

                <div class="uk-margin-medium-bottom"/>
                <Button :action="login" :is-loading="loadingLogin" :text="$t('login')" has-action is-large is-max
                        mode="primary"/>

                <div v-if="errorLogin" class="uk-margin-medium-bottom"/>
                <ErrorMessage
                    :error="errorLogin"
                    :custom-errors="[{code: 400, message: $t('terminal.pin.invalid')}, {code: 401, message: $t('terminal.pin.notset')}]"/>

            </div>

        </Modal>

        <Modal :id="modalId+'-confirm'" :title="$t('terminal.action.confirm.title')">
            <div class="description uk-text-center">
                <Translate translation-key="terminal.action.confirm.message"/>

                <div class="uk-margin-medium-top"/>
                <div class="uk-grid uk-child-width-1-1 uk-grid-divider" uk-grid>
                    <div>
                        <Button has-action is-max is-large :action="postAction" mode="primary">
                            <Translate :translation-key="selectedAction"/>
                        </Button>
                    </div>
                    <div>
                        <Button is-large is-max mode="default" class="uk-modal-close">
                            <Translate translation-key="cancel"/>
                        </Button>
                    </div>
                </div>

                <div v-if="errorAction" class="uk-margin-medium-bottom"/>
                <ErrorMessage :error="errorAction"/>

            </div>
        </Modal>

    </span>
</template>


<script>

import Modal from "@/components/generic/Modal.vue";
import TextField from "@/components/generic/TextField.vue";
import Button from "@/components/generic/Button.vue";
import ErrorMessage from "@/components/generic/ErrorMessage.vue";
import axios from "axios";
import Icon from "@/components/generic/Icon.vue";
import Card from "@/components/generic/Card.vue";
import Translate from "@/components/Translate.vue";
import UIkit from "uikit";
import TerminalConnnectorNotRunnning from "@/components/TerminalConnnectorNotRunnning.vue";

export default {
    name: 'TerminalControlForm',
    components: {TerminalConnnectorNotRunnning, Translate, Card, Icon, ErrorMessage, Button, TextField, Modal},
    props: {
        modalId: String,
    },
    data() {
        return {
            authenticated: false,
            trigger: 0,

            actions: null,

            inputFields: [
                {
                    value: "",
                    valid: false,

                    name: "terminal-pin",
                    label: "terminal.pin",
                    icon: "lock",
                    rules: "required|digits:4",
                    autocomplete: "terminal-pin",

                    type: "password"
                },
            ],

            errorLogin: null,
            loadingLogin: false,

            loadingAction: false,
            errorAction: null,

            selectedAction: "",
        }
    },
    mounted() {
        let self = this;

        const modalElement = document.getElementById(this.modalId);
        modalElement.addEventListener("hidden", function () {
            self.authenticated = false;
            self.inputFields[0].value = "";
            self.inputFields[0].valid = false;
        });

        const modalConfirmElement = document.getElementById(this.modalId + '-confirm');
        modalConfirmElement.addEventListener("hidden", function () {
            self.authenticated = false;
            self.inputFields[0].value = "";
            self.inputFields[0].valid = false;
        });
    },
    methods: {
        prepareAction(name) {
            this.selectedAction = name;
            UIkit.modal("#" + this.modalId + '-confirm').show();
        },
        setValid(valid, fieldKey) {
            this.errorLogin = null;
            this.inputFields[fieldKey].valid = valid
        },
        login() {
            this.trigger++;

            for (let i = 0, j = this.inputFields.length; i < j; i++) {
                if (!this.inputFields[i].valid) {
                    return;
                }
            }

            this.loadingLogin = true;
            let http = axios.create();
            http.post(this.apiUrl + '/terminals/authenticate-pin', {}, {
                headers: {
                    "pin": this.inputFields[0].value,
                },

            }).then(() => {
                this.errorLogin = null;
                this.loadingLogin = false;
                this.authenticated = true;

                this.getActions();

                this.$emit('created');

            }).catch(e => {
                this.checkGenericError(e);
                this.errorLogin = e;
                this.loadingLogin = false;

            });
        },
        getActions() {
            if (this.actions && this.actions.length > 0) {
                return;
            }

            let http = axios.create();
            http.get(this.apiUrlTerminal + '/actions').then(response => {
                this.actions = response.data ? response.data : [];

            }).catch(() => {

            });
        },
        postAction() {
            this.loadingAction = true;

            let http = axios.create();
            http.post(this.apiUrlTerminal + '/actions/' + this.selectedAction).then(() => {
                this.errorAction = null;
                this.loadingAction = false;
                UIkit.modal("#" + this.modalId + '-confirm').hide();

            }).catch(e => {
                this.loadingAction = false;
                this.errorAction = e;

            });
        },
    },
}

</script>


<style lang="scss" scoped>

.forgot-struct {
    margin-top: 5px;
}

.forgot {
    color: #000 !important;
    font-size: 14px;
}

.action-name {
    font-size: $site-font-size-large;
}

.description {
    font-size: $site-font-size-large;
}

</style>
