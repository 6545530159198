<template>
    <div v-if="success" :class="withoutAnimation ? '' : 'uk-animation-fade'">

        <InfoBox :closeable="closeable" mode="success">
            {{ message }}
            <slot/>
        </InfoBox>

    </div>
</template>

<script>

import InfoBox from "@/components/generic/InfoBox";

export default {
    name: 'SuccessMessage',
    components: {InfoBox},
    props: {
        success: Boolean,
        message: String,
        closeable: Boolean,
        withoutAnimation: Boolean,
    },
}

</script>

<style lang="scss" scoped>

</style>