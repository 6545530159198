import { render, staticRenderFns } from "./FooterText.vue?vue&type=template&id=e214168e&scoped=true"
import script from "./FooterText.vue?vue&type=script&lang=js"
export * from "./FooterText.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e214168e",
  null
  
)

export default component.exports