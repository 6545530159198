<template>
    <div :class="keyboardClass"/>
</template>


<script>

import Keyboard from "simple-keyboard";

export default {
    name: "SimpleKeyboard",
    props: {
        keyboardClass: {
            default: "simple-keyboard",
            type: String
        },
        input: {
            type: String
        },
        hasNumpad: Boolean,
    },
    data() {
        return {
            keyboard: null,

            layoutEN: {
                default: [
                    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                    "{tab} q w e r t y u i o p [ ] \\",
                    "{lock} a s d f g h j k l ; ' {enter}",
                    "{shift} z x c v b n m , . / {shift}",
                    ".com @ {space}",
                ],
                shift: [
                    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                    "{tab} Q W E R T Y U I O P { } |",
                    '{lock} A S D F G H J K L : " {enter}',
                    "{shift} Z X C V B N M < > ? {shift}",
                    ".com @ {space}",
                ],
            },

            layoutFR: {
                default: [
                    "` 1 2 3 4 5 6 7 8 9 0 \u00B0 + {bksp}",
                    "{tab} a z e r t y u i o p ^ $",
                    "{lock} q s d f g h j k l m \u00F9 * {enter}",
                    "{shift} < w x c v b n , ; : ! {shift}",
                    ".fr @ {space}",
                ],
                shift: [
                    "\u00B2 & \u00E9 \" ' ( - \u00E8 _ \u00E7 \u00E0 ) = {bksp}",
                    "{tab} A Z E R T Y U I O P \u00A8 \u00A3",
                    "{lock} Q S D F G H J K L M % \u00B5 {enter}",
                    "{shift} > W X C V B N ? . / \u00A7 {shift}",
                    ".fr @ {space}",
                ],
            },

            layoutDE: {
                default: [
                    "^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp}",
                    "{tab} q w e r t z u i o p \u00FC +",
                    "{lock} a s d f g h j k l \u00F6 \u00E4 # {enter}",
                    "{shift} < y x c v b n m , . - {shift}",
                    ".de @ {space}",
                ],
                shift: [
                    '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {bksp}',
                    "{tab} Q W E R T Z U I O P \u00DC *",
                    "{lock} A S D F G H J K L \u00D6 \u00C4 ' {enter}",
                    "{shift} > Y X C V B N M ; : _ {shift}",
                    ".de @ {space}",
                ],
            },


            layoutAT: {
                default: [
                    "^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp}",
                    "{tab} q w e r t z u i o p \u00FC +",
                    "{lock} a s d f g h j k l \u00F6 \u00E4 # {enter}",
                    "{shift} < y x c v b n m , . - {shift}",
                    ".at @ {space}",
                ],
                shift: [
                    '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {bksp}',
                    "{tab} Q W E R T Z U I O P \u00DC *",
                    "{lock} A S D F G H J K L \u00D6 \u00C4 ' {enter}",
                    "{shift} > Y X C V B N M ; : _ {shift}",
                    ".at @ {space}",
                ],
            },


            layoutCH: {
                default: [
                    "^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp}",
                    "{tab} q w e r t z u i o p \u00FC +",
                    "{lock} a s d f g h j k l \u00F6 \u00E4 # {enter}",
                    "{shift} < y x c v b n m , . - {shift}",
                    ".ch @ {space}",
                ],
                shift: [
                    '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {bksp}',
                    "{tab} Q W E R T Z U I O P \u00DC *",
                    "{lock} A S D F G H J K L \u00D6 \u00C4 ' {enter}",
                    "{shift} > Y X C V B N M ; : _ {shift}",
                    ".ch @ {space}",
                ],
            },


            layoutLI: {
                default: [
                    "^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp}",
                    "{tab} q w e r t z u i o p \u00FC +",
                    "{lock} a s d f g h j k l \u00F6 \u00E4 # {enter}",
                    "{shift} < y x c v b n m , . - {shift}",
                    ".li @ {space}",
                ],
                shift: [
                    '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {bksp}',
                    "{tab} Q W E R T Z U I O P \u00DC *",
                    "{lock} A S D F G H J K L \u00D6 \u00C4 ' {enter}",
                    "{shift} > Y X C V B N M ; : _ {shift}",
                    ".li @ {space}",
                ],
            },


            layoutNL: {
                default: [
                    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                    "{tab} q w e r t y u i o p [ ] \\",
                    "{lock} a s d f g h j k l ; ' {enter}",
                    "{shift} z x c v b n m , . / {shift}",
                    ".nl @ {space}",
                ],
                shift: [
                    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                    "{tab} Q W E R T Y U I O P { } |",
                    '{lock} A S D F G H J K L : " {enter}',
                    "{shift} Z X C V B N M < > ? {shift}",
                    ".nl @ {space}",
                ],
            },

            layoutFI: {
                default: [
                    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                    "{tab} q w e r t y u i o p [ ] \\",
                    "{lock} a s d f g h j k l ; ' {enter}",
                    "{shift} z x c v b n m , . / {shift}",
                    ".fi @ {space}",
                ],
                shift: [
                    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                    "{tab} Q W E R T Y U I O P { } |",
                    '{lock} A S D F G H J K L : " {enter}',
                    "{shift} Z X C V B N M < > ? {shift}",
                    ".fi @ {space}",
                ],
            },

            layoutIT: {
                default: [
                    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                    "{tab} q w e r t y u i o p [ ] \\",
                    "{lock} a s d f g h j k l ; ' {enter}",
                    "{shift} z x c v b n m , . / {shift}",
                    ".it @ {space}",
                ],
                shift: [
                    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                    "{tab} Q W E R T Y U I O P { } |",
                    '{lock} A S D F G H J K L : " {enter}',
                    "{shift} Z X C V B N M < > ? {shift}",
                    ".it @ {space}",
                ],
            },

            layoutNumpad: {
                default: [
                    "{numpad7} {numpad8} {numpad9}",
                    "{numpad4} {numpad5} {numpad6}",
                    "{numpad1} {numpad2} {numpad3}",
                    "{numpad0} {bksp} {enter}"
                ]
            }
        }
    },
    mounted() {
        if (!this.keyboard) {
            let determinedLayout = this.getLayoutByLang();

            if (this.hasNumpad) {
                determinedLayout = this.layoutNumpad;
            }

            this.keyboard = new Keyboard(this.keyboardClass, {
                onChange: this.onChange,
                onKeyPress: this.onKeyPress,
                layout: determinedLayout,
                display: {
                    '{enter}': '↩',
                    '{tab}': '↹',
                    '{lock}': '⇪',
                    '{shift}': '⇧',
                    '{bksp}': "⌫",
                    '{space}': " ",

                    '{numpad9}': "9",
                    '{numpad8}': "8",
                    '{numpad7}': "7",
                    '{numpad6}': "6",
                    '{numpad5}': "5",
                    '{numpad4}': "4",
                    '{numpad3}': "3",
                    '{numpad2}': "2",
                    '{numpad1}': "1",
                    '{numpad0}': "0",
                },
            });
        }
    },
    methods: {
        getLayoutByLang() {
            const lang = this.$i18n.locale;

            if (lang === "de") {
                return this.layoutDE;
            }

            if (lang === "ch") {
                return this.layoutCH;
            }

            if (lang === "at") {
                return this.layoutAT;
            }

            if (lang === "li") {
                return this.layoutLI;
            }

            if (lang === "nl") {
                return this.layoutNL;
            }

            if (lang === "fi") {
                return this.layoutFI;
            }

            if (lang === "en") {
                return this.layoutEN;
            }

            if (lang === "it") {
                return this.layoutIT;
            }

            if (lang === "fr") {
                return this.layoutFR;
            }

            return this.layoutEN;
        },
        onChange(input) {
            this.$emit("onChange", input);
        },
        onKeyPress(button) {
            this.$emit("onKeyPress", button);

            if (button === "{enter}") {
                this.$emit("onEnter");
            }

            if (button === "{shift}" || button === "{lock}") this.handleShift();
        },
        handleShift() {
            let currentLayout = this.keyboard.options.layoutName;
            let shiftToggle = currentLayout === "default" ? "shift" : "default";

            this.keyboard.setOptions({
                layoutName: shiftToggle
            });
        }
    },
    watch: {
        input(input) {
            this.keyboard.setInput(input);
        }
    }
};
</script>

<style scoped>


</style>
