<template>
    <span
        :uk-tooltip="disabled ? 'title:'+$t('x.module.disabled.hint', {x:$t('terminal')}) : ''"
        :class="disabled ? 'module-disabled-struct' : ''">
        <span :class="disabled ? 'module-disabled uk-height-1-1' : 'uk-height-1-1'">
            <Card is-dark mode="default" :title="$t(title)" class="uk-height-1-1">
                <Description v-if="description" :text="$t(description)"/>

                 <div class="uk-child-width-auto uk-grid-match uk-flex-center" uk-grid>

                                <div v-for="download in connectors" :key="download.name"
                                     class="uk-text-center">
                                    <a :href="download.link">
                                        <RoundButton
                                            icon="cloud-download"/>
                                    </a>
                                    <div class="uk-margin-small-top system-title uk-link-reset">
                                        <a :href="download.link">
                                            {{ download.name }}
                                        </a>
                                    </div>
                                </div>
                             </div>

                <div v-if="false">
                 <a :href="getMainOS().link">
                     <Button :text="$t('download.for.x', {x: getMainOS().name})"
                             has-action
                             is-large
                             is-max
                             mode="primary"
                     />
                 </a>

                <div v-if="getOtherOS() && getOtherOS().name"
                     class="uk-margin-small-top">
                    <Translate translation-key="not.your.os"/> <a :href="getOtherOS().link"><Translate
                    translation-key="download.for.x" :parameter="{x:getOtherOS().name}"/></a>
                </div>
                    </div>



                  <div class="uk-margin-medium-top"/>
                                    <span v-if="isTerminalRegistered || codeRegened">
                                        <ToggleItem :is-disabled="code !== null"
                                                    toggle="target: #terminal-regen-code">
                                            <Button :text="$t('regen.setup.code')"
                                                    :is-disabled="code !== null"
                                                    is-large is-max mode="danger"/>
                                        </ToggleItem>

                                        <Modal id="terminal-regen-code" :title="$t('regen.setup.code')">
                                            <div class="uk-margin-medium-bottom uk-text-center"
                                                 v-html="$t('terminalConnector.regen.setup.code.warning')"/>

                                            <div v-if="!successReset" class="uk-flex uk-flex-center" uk-grid>
                                                <div>
                                                    <Button :text="$t('cancel')" class="uk-modal-close" is-large
                                                            is-max is-min-width
                                                            mode="default"/>
                                                </div>
                                                <div>
                                                    <Button :action="regenCode" :is-loading="loadingCode"
                                                            :text="$t('regen')"
                                                            has-action
                                                            is-large is-max
                                                            is-min-width mode="danger"/>
                                                </div>
                                            </div>
                                        </Modal>
                                    </span>

                                    <Button
                                        v-else
                                        :text="$t('gen.setup.code')"
                                        is-large
                                        :action="genCode" has-action
                                        :is-loading="loadingCode"
                                        is-max is-min-width
                                        :is-disabled="code !== null"
                                        mode="success"/>

                                    <div v-if="errorCode" class="uk-margin-medium-bottom"/>
                                    <ErrorMessage :error="errorCode"/>


                                    <div v-if="successCode" class="uk-margin-medium-bottom"/>
                                    <div v-if="successCode" class="uk-animation-fade" id="code">
                                        <TextField icon="code"
                                                   classes="cursor-pointer"
                                                   name="setup-code"
                                                   label="setup.code"
                                                   :tooltip="$t('copy.to.clipboard')"
                                                   v-model="codeValue"
                                                   :on-click="codeToClipboard"
                                                   :on-change="resetCode"
                                                   :action-function="codeToClipboard"
                                                   action-icon="copy-clipboard"
                                                   :action-tooltip="$t('copy.to.clipboard')"/>
                                    </div>

                                    <div v-if="successCode" class="uk-margin-medium-bottom"/>
                                    <SuccessMessage
                                        :message="$t('gen.setup.code.success')"
                                        :success="successCode"/>




            </Card>
        </span>
    </span>

</template>


<script>

import Card from "@/components/generic/Card.vue";
import Button from "@/components/generic/Button.vue";
import Translate from "@/components/Translate.vue";
import Description from "@/components/Description.vue";
import ErrorMessage from "@/components/generic/ErrorMessage.vue";
import TextField from "@/components/generic/TextField.vue";
import Modal from "@/components/generic/Modal.vue";
import ToggleItem from "@/components/generic/ToggleItem.vue";
import SuccessMessage from "@/components/generic/SuccessMessage.vue";
import axios from "axios";
import UIkit from "uikit";
import RoundButton from "@/components/RoundButton.vue";

export default {
    name: 'TerminalConnectorForm',
    components: {
        RoundButton,
        SuccessMessage,
        ToggleItem,
        Modal,
        TextField,
        ErrorMessage,
        Description, Translate, Button, Card
    },
    props: {
        connectors: Array,
        disabled: Boolean,
        isTerminalRegistered: Boolean,
    },
    data() {
        return {
            title: "terminal.connector",
            description: "terminal.connector.description",

            code: null,
            codeValue: null,
            codeRegened: false,
            errorCode: null,
            loadingCode: false,
            successCode: false,

            successReset: false,
        }
    },
    methods: {
        genCode(closeModal = false) {
            this.loadingCode = true;
            let http = axios.create();
            http.post(this.apiUrl + '/terminal-token').then((response) => {
                this.code = response.data.token;
                this.codeValue = response.data.token;

                this.errorCode = null;
                this.loadingCode = false;
                this.successCode = true;

                this.$emit('created');

                if (closeModal) {
                    UIkit.modal("#terminal-regen-code").hide();
                }

                setTimeout(function () {
                    let elementId = "code";
                    UIkit.scroll("#" + elementId, {offset: 15}).scrollTo("#" + elementId);
                }, 10);

            }).catch(e => {
                this.checkGenericError(e);
                this.errorCode = e;
                this.loadingCode = false;
                this.successCode = false;

            });
        },
        codeToClipboard() {
            this.copyToClipboard(this.code);
        },
        resetCode() {
            this.codeValue = this.code;
        },
        regenCode() {
            this.codeRegened = true;
            this.genCode(true);
        },
        getMainOS() {
            let osName = window.navigator.userAgent;
            osName = osName.toLowerCase();

            for (let i = 0, j = this.connectors; i < j; i++) {
                if (osName.includes(this.connectors[i].name)) {
                    return this.connectors[i];
                }
            }

            return this.connectors[0];
        },
        getOtherOS() {
            let mainOS = this.getMainOS();

            for (let i = 0, j = this.connectors; i < j; i++) {
                if (mainOS.name === this.connectors[i].name) {
                    continue;
                }

                return this.connectors[i];
            }

            return {};
        }
    },
}

</script>


<style lang="scss" scoped>

.system-title {
    font-weight: 500;
    font-size: $site-font-size-large;
}

</style>
