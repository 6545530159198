<template>
    <div class="site-struct single">

        <LoadingStruct is-dark :is-loading="false">
            <Card :title="$t('set.password')" is-dark mode="default">

                <span v-for="(inputField, key) in inputFields" :key="inputField.name">
                    <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                               :autofocus="inputField.autofocus" :field-key="key"
                               :hasPasswordMeter="inputField.hasPasswordMeter"
                               :icon="inputField.icon"
                               :is-optional="inputField.optional"
                               :label="inputField.label"
                               :name="inputField.name"
                               :on-enter="reset"
                               :rules="inputField.rules"
                               :type="inputField.type"
                               :validate="trigger"
                               @setValid="setValid"
                    />
                    <div v-if="key < inputFields.length-1" class="uk-margin-bottom"/>
                </span>

                <div v-if="!success" class="uk-margin-medium-bottom"/>
                <Button v-if="!success" :action="reset" :is-loading="loading" :text="$t('reset')" has-action is-large
                        is-max mode="primary"/>

                <div v-if="error" class="uk-margin-medium-bottom"/>
                <ErrorMessage
                    :custom-errors="[{code: 400, message: $t('invalid.reset.token')}, {code: 422, message: $t('invalid.reset.token')}]"
                    :error="error"/>

                <div v-if="success" class="uk-margin-medium-bottom"/>
                <SuccessMessage :message="$t('password.reset.confirm.success')" :success="success"/>

                <div v-if="success" class="uk-margin-bottom"/>
                <LinkObject v-if="success" to="login">
                    <Button :text="$t('login')" is-large is-max mode="primary"/>
                </LinkObject>
            </Card>
        </LoadingStruct>

    </div>
</template>

<script>

import LoadingStruct from "@/views/LoadingStruct";
import Card from "@/components/generic/Card";
import TextField from "@/components/generic/TextField";
import Button from "@/components/generic/Button";
import axios from "axios";
import ErrorMessage from "@/components/generic/ErrorMessage";
import SuccessMessage from "@/components/generic/SuccessMessage";
import LinkObject from "@/components/LinkObject";

export default {
    name: 'ResetPasswordConfirm',
    components: {
        LinkObject,
        SuccessMessage,
        ErrorMessage,
        Button,
        TextField,
        Card,
        LoadingStruct,
    },
    data() {
        return {
            trigger: 0,
            inputFields: [
                {
                    value: "",
                    valid: false,

                    name: "new-password",
                    label: "new.password",
                    icon: "lock",
                    rules: "required|min:8|max:4096",
                    autocomplete: "new-password",
                    type: "password",
                    hasPasswordMeter: true,
                },
            ],

            loading: false,
            error: null,
            success: false,
        }
    },
    methods: {
        setValid(valid, fieldKey) {
            this.inputFields[fieldKey].valid = valid
        },
        reset() {
            this.trigger++;

            for (let i = 0, j = this.inputFields.length; i < j; i++) {
                if (!this.inputFields[i].valid) {
                    return;
                }
            }

            this.loading = true;
            let http = axios.create();

            http.post(this.apiUrl + '/reset-password-confirm', {
                "token": this.$route.query.token,
                "newPassword": this.inputFields[0].value,

            }).then(() => {
                this.error = null;
                this.loading = false;
                this.success = true;

            }).catch(e => {
                this.checkGenericError(e);
                this.error = e;
                this.loading = false;
                this.success = false;

            })
        }
    }
}

</script>

<style lang="scss" scoped>

</style>
