<template>
    <div :class="answered ? 'uk-animation-reverse uk-animation-fade uk-hidden' : (show ? '' : 'uk-hidden')">

        <hr v-if="isPromptInstaller" class="uk-margin-medium-top uk-margin-medium-bottom"/>

        <div v-if="isPromptInstaller" class="prompt-struct uk-text-center">
            <Card is-dark mode="default" is-without-body is-blank>
                <div class="uk-grid-small uk-width-auto uk-flex uk-flex-center uk-flex-middle" uk-grid>
                    <div>
                        <img src="/img/icons/android-chrome-512x512.png" class="logo uk-margin-small-right"
                             uk-img/>
                        <span class="name">{{ name }}</span>
                    </div>
                    <div>
                        <Button has-action :action="promptInstaller" mode="primary">{{ $t('install') }}</Button>
                    </div>
                </div>

            </Card>
        </div>

    </div>
</template>


<script>

import Button from "@/components/generic/Button";
import Card from "@/components/generic/Card.vue";

export default {
    name: 'PWAPromptForm',
    components: {Card, Button},
    props: {
        name: String,
        promptInstallerEvent: Event,
    },
    data() {
        return {
            show: true,
            answered: false,
            cookieKey: 'pwa',

            isPromptInstaller: false,
            interval: 0,
        }
    },
    mounted() {
        let self = this;
        this.interval = setInterval(function () {
            if (self.promptInstallerEvent !== null) {
                self.isPromptInstaller = true;
                clearInterval(self.interval);
            }
        }, 50);
    },
    methods: {
        promptInstaller() {
            this.promptInstallerEvent.prompt()
            this.promptInstallerEvent.userChoice.then((choice) => {
                if (choice.outcome === 'accepted') {
                    this.accept();
                }
            })
        },
        accept() {
            this.answered = true;
            this.$store.state.pwa = "accepted";
            this.$cookie.set(this.cookieKey, "accepted", {expires: this.cookieMaxExpiry});
        },
    }
}

</script>


<style lang="scss" scoped>

.logo {
    height: 50px;
}

.name {
    font-weight: 500;
    font-size: $site-font-size-large;
}

</style>
