<template>
    <div v-if="error" class="uk-animation-fade">

            <span v-if="!error || !error.response">
                {{ $t('no.connection.message') }}
            </span>
            <span v-else-if="customErrorExists">
                <span v-for="customError in customErrors" :key="customError.code">
                    <span v-if="error.response.status === customError.code">
                        {{ customError.message }}
                    </span>
                </span>
            </span>
            <span v-else-if="error.response.status === 422">
                {{ $t('client.error.message') }}

                <div class="uk-margin-medium-bottom"/>
                <Button :action="reload" :text="$t('reload')" has-action is-large is-max mode="primary"/>
            </span>
            <span v-else-if="error.response.status === 429">
                <countdown :end-time="new Date(error.response.headers['x-ratelimit-reset']  * 1000).getTime()"
                           @finish="setRateLimitInactive"
                           @start="setRateLimitActive">
                    <span slot="process" slot-scope="{ timeObj }">
                        <span v-if="timeObj.ceil.m === 1">
                            {{ $t('tooManyRequestsMessageMinute', {minutes: timeObj.ceil.m}) }}
                        </span>
                        <span v-else>
                            {{ $t('tooManyRequestsMessageMinutes', {minutes: timeObj.ceil.m}) }}
                        </span>
                    </span>
                </countdown>
                <span v-if="isRateLimitInactive">
                      {{ $t('tooManyRequestsRetry') }}
                </span>
            </span>
            <span v-else-if="error.response.status === 500">
                {{ $t('server.error') }}
            </span>
            <span v-else>
                {{ $t('unexpected.error') }}
            </span>

    </div>
</template>

<script>

import Button from "@/components/generic/Button.vue";

export default {
    name: 'ErrorMessageString',
    components: {Button},
    props: {
        error: Error,
        customErrors: {
            code: Number,
            message: String,
        },
    },
    data() {
        return {
            isRateLimitInactive: false,
        }
    },
    methods: {
        setRateLimitActive() {
            this.isRateLimitInactive = false;
        },
        setRateLimitInactive() {
            this.isRateLimitInactive = true;
        },
    },
    computed: {
        customErrorExists() {
            if (!this.error || !this.error.response || !this.customErrors) {
                return false;
            }

            for (let i = 0, j = this.customErrors.length; i < j; i++) {
                if (this.customErrors[i].code === this.error.response.status) {
                    return true;
                }
            }

            return false;
        }
    }
}

</script>

<style lang="scss" scoped>

</style>