<template>
    <div>
        <div v-if="isLoading">
            <div v-if="!isSpinnerHidden"
                 :class="'uk-height-small uk-flex uk-flex-center uk-flex-middle' + (isDark ? '' : ' spinner')"
                 uk-spinner/>
        </div>
        <div v-else
             :class="isAnimationDisabled? '' : 'uk-animation-fade'">
            <slot/>
        </div>
    </div>
</template>

<script>

export default {
    name: 'LoadingStruct',
    props: {
        isLoading: Boolean,
        isDark: Boolean,
        isAnimationDisabled: Boolean,
        isSpinnerHidden: Boolean,
    },
}

</script>

<style lang="scss" scoped>

.spinner {
    color: $site-background;
}

</style>
