<template>
    <span :class="disabled ? 'module-disabled-struct' : ''"
          :uk-tooltip="disabled ? 'title:'+$t('x.module.disabled.hint', {x:$t('gallery')}) : ''">
        <span :class="disabled ? 'module-disabled uk-height-1-1' : 'uk-height-1-1'">

            <Translate translation-key="article.list.description"/>
            <div class="uk-margin-medium-top"/>

            <div>
                <Button :action="actionNew"
                        has-action mode="primary">
                {{ $t('x.add', {x: $t('article.list')}) }}
                </Button>


            <div class="uk-margin-top"/>
            <div v-if="!computedList || computedList.length === 0">
                {{ $t('none.x', {x: $t(title)}) }}
            </div>
            <div v-else class="uk-overflow-auto">

                <table class="uk-table uk-table-middle uk-table-divider uk-table-hover">
                    <thead>
                    <tr>
                        <th class="uk-table-shrink">
                            {{ $t('name') }}
                        </th>

                        <th class="uk-table-shrink">
                            <Translate :parameter="{x:$t('articles')}" translation-key="linked.x"/>
                        </th>

                        <th class="uk-table-shrink">
                            <Translate :parameter="{x:$t('galleries')}" translation-key="linked.x"/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(articleList, key) in computedList" :key="'article-'+key"
                        :class="'cursor-pointer uk-text-nowrap' + (articleList.id === hoveredArticleId ? ' uk-table-hovered' : '')">
                        <td v-on:click="actionSelect(articleList)">
                               <Icon icon="tag" class="uk-margin-small-right"/>
                            {{ articleList.name }}
                        </td>

                        <td v-on:click="actionSelect(articleList)">
                               <Icon icon="cart" class="uk-margin-small-right"/>

                            <span v-if="articleList.articleRefs"
                                  v-html="articleList.articleRefs.length"/>
                            <span v-else>0</span>

                            <Translate v-if="articleList.articleRefs && articleList.articleRefs.length ===1"
                                       translation-key="article"/>
                            <Translate v-else translation-key="articles"/>
                        </td>

                        <td v-on:click="actionSelect(articleList)">
                               <Icon icon="thumbnails" class="uk-margin-small-right"/>

                            <span v-if="articleList.galleries" v-html="articleList.galleries.length"/>
                            <span v-else>0</span>

                            <Translate v-if="articleList.galleries && articleList.galleries.length===1"
                                       translation-key="gallery"/>
                            <Translate v-else translation-key="galleries"/>
                        </td>
                    </tr>

                    </tbody>
                </table>

            </div>


            <Modal :id="modalIdDelete"
                   :title="$t('x.delete', {x: $t('article.list')})">
                <div class="uk-text-center">
                    <Translate translation-key="article.list.delete.confirm"/>
                </div>

                <div class="uk-flex uk-flex-center uk-margin-medium-top" uk-grid>
                    <div>
                        <Button :text="$t('cancel')" class="uk-modal-close" is-large
                                is-max is-min-width
                                mode="default"/>
                    </div>
                    <div>
                        <Button :action="actionDelete" :is-loading="loadingDelete"
                                :text="$t('x.delete', {x: $t('article.list')})"
                                has-action is-large
                                is-max is-min-width mode="danger"/>
                    </div>
                </div>
            </Modal>



            <Modal :id="modalIdDetails"
                   id-title="modal-title"
                   is-extended
                   :title="$t(selected ? (selectedClone ? 'x.duplicate' : 'x.change') : 'x.add', {x: $t('article.list')})">

                <div uk-grid
                     class="uk-grid-small uk-grid-divider">

                    <div class="uk-width-1-4@m uk-width-1-1">
                        <span v-for="(inputField, key) in fieldsName" :key="inputField.name">
                            <TextField v-model="inputField.value"
                                       :autocomplete="inputField.autocomplete"
                                       :autofocus="inputField.autofocus"
                                       :field-key="key"
                                       :hasPasswordMeter="inputField.hasPasswordMeter"
                                       :icon="inputField.icon"
                                       :is-optional="inputField.optional"
                                       :label="inputField.label"
                                       :name="inputField.name"
                                       :options="inputField.options"
                                       :rules="inputField.rules"
                                       :steps="inputField.steps"
                                       :type="inputField.type"
                                       :label-info="inputField.labelInfo"

                                       :validate="trigger"
                                       @setValid="setValidFieldsName"
                            />

                            <div v-if="key < fieldsName.length-1" class="uk-margin-bottom"/>
                        </span>

                        <div v-if="selected">
                            <div class="uk-margin-top"/>

                            <LabelWithError :label="$t('linked.x', {x:$t('galleries')})"/>
                            <ul class="uk-list uk-list-medium uk-list-disc">
                                <li v-for="gallery in selected.galleries" :key="gallery.id">
                                    {{ gallery.name }}
                                </li>
                            </ul>

                              <div v-if="selected.galleries === 0">
                                  {{ $t('no.galleries') }}
                              </div>

                        </div>

                    </div>
                    <div class="uk-width-expand@m uk-width-1-1">

                        <LabelWithError :label="$t('article.refs')"/>
                        <div class="uk-margin-bottom"/>

                        <LabelWithError :label="$t('article')+'*'" :error="errorArticle"/>

                        <div v-if="settings.articles.length === 0">
                               <Translate translation-key="none.x"
                                          :parameter="{x: $t('articles')}"/>
                        </div>
                        <div v-else-if="filteredArticles.length === 0">
                              <Translate translation-key="article.refs.all.used"/>
                        </div>
                        <div v-else>
                            <select v-model="selectedArticleID"
                                    id="article-selector"
                                    v-on:change="errorArticle = null"
                                    class="uk-select uk-input-styled">
                                <option v-for="option in filteredArticles"
                                        :key="'a'+option.id"
                                        :value="option.id">
                                    {{ renderArticleSelect(option) }}
                                </option>
                            </select>

                            <div class="uk-margin-small-bottom"/>
                            <Translate translation-key="article.refs.description"/>

                            <div class="uk-margin-bottom"/>
                            <span v-on:click="addArticleRef()">
                                <Button mode="primary">
                                    {{ $t('x.add', {x: $t('article.ref')}) }}
                                </Button>
                            </span>

                         </div>

                        <div class="uk-margin-medium-bottom"/>

                        <div
                            v-if="!articleRefs || articleRefs.length === 0">
                            {{ $t('none.x', {x: $t('article.refs')}) }}
                        </div>
                        <div v-else
                             class="uk-grid-divider uk-child-width-1-1"
                             uk-grid>

                            <div v-for="(item, key) in articleRefs"
                                 :key="'article.ref'+key"
                                 :id="'article-ref-'+item.articleID">

                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-expand">
                                        <LabelWithError :label="$t('article')+'*'"/>
                                            <div :uk-tooltip="renderArticleSelect(getArticleByID(item.articleID))">
                                                <select :value="item.articleID"
                                                        class="uk-select uk-input-styled"
                                                        disabled>
                                                    <option v-for="option in settings.articles"
                                                            :key="'ar'+option.id"
                                                            :value="option.id">
                                                    {{ renderArticleSelect(option) }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="uk-width-auto">
                                            <LabelWithError class="uk-invisible" label="p"/>
                                            <div class="trash-distance"/>
                                            <div
                                                :uk-tooltip="'title:'+$t('x.delete', {x: $t('article.ref')})"
                                                class="cursor-pointer"
                                                v-on:click="removeArticleRef(key)">
                                                <Icon icon="trash"/>
                                            </div>
                                        </div>
                                    </div>






                                    <div class="uk-margin-bottom"/>

                                    <div class="uk-grid-small uk-grid-divider" uk-grid>
                                        <div class="uk-width-expand@m uk-width-1-1">

                                            <div class="uk-grid-small  uk-flex uk-flex-middle" uk-grid>
                                                <div class="uk-width-expand">
                                                       <LabelWithError :label="$t('active')"/>
                                                </div>
                                                <div class="uk-width-auto">
                                                     <SwitchButton v-model="item.overrideActive"
                                                                   is-small
                                                                   :item-key="key"
                                                                   @input="handleOverrideActive"
                                                                   :id="'overrideActive'+item.articleID"
                                                                   :label="$t('override')"/>
                                                </div>
                                            </div>
                                            <SwitchButton :is-disabled="!item.overrideActive"
                                                          v-model="item.active"
                                                          @input="handleActive"
                                                          is-label-hidden
                                                          :item-key="key"
                                                          :id="'item-active'+key"
                                                          :label="'item-active-override'"/>






                                            <div class="uk-margin-top"/>
                                            <CurrencyInput
                                                :is-disabled="!item.overridePrice"
                                                v-model="item.price"
                                                :options="getComputedCurrencyOptions"
                                                icon="tag"
                                                :label="$t('price')"
                                                name="price"
                                                :validate="trigger"
                                                rules="required"
                                                @input="success= false"
                                            >
                                                <template v-slot:besidesLabel>
                                                       <SwitchButton v-model="item.overridePrice"
                                                                     is-small
                                                                     :item-key="key"
                                                                     @input="handleOverridePrice"
                                                                     :id="'overridePrice'+item.articleID"
                                                                     :label="$t('override')"/>
                                                </template>
                                            </CurrencyInput>



                                        </div>
                                        <div class="uk-width-expand">
                                            <div class="uk-grid-small uk-flex uk-flex-middle" uk-grid>
                                                <div class="uk-width-expand">
                                                    <LabelWithError
                                                        :label="$t('graduated.prices') + ' ('+$t('optional')+')'"/>
                                                </div>
                                                <div>
                                                 <SwitchButton v-model="item.overrideGraduatedPrices"
                                                               is-small
                                                               :item-key="key"
                                                               @input="handleOverrideGraduatedPrices"
                                                               :id="'graduatedPrices'+item.articleID"
                                                               :label="$t('override')"/>
                                                </div>
                                            </div>


                                            <div v-if="item.overrideGraduatedPrices">
                                                <span
                                                    v-on:click="actionAddGraduatedPrices(item)">
                                                    <Button mode="primary">
                                                        <Translate translation-key="x.add"
                                                                   :parameter="{x: $t('graduated.price')}"/>
                                                    </Button>
                                                    <div class="uk-margin-bottom"/>
                                                </span>
                                            </div>

                                                <div v-if="!item.graduatedPrices || item.graduatedPrices.length === 0">
                                                    {{ $t('none.x', {x: $t('graduated.prices')}) }}
                                                </div>

                                                <div v-for="(graduatedPrice, gkey) in item.graduatedPrices"
                                                     :key="'ref.graduated.price'+gkey"
                                                     class="uk-grid-small uk-grid-match uk-child-width-expand@m uk-child-width-1-1"
                                                     uk-grid>
                                                        <div>
                                                            <TextField
                                                                :disabled="!item.overrideGraduatedPrices"
                                                                :value="graduatedPrice.amount"
                                                                :field-key="key"
                                                                name="at.amount"
                                                                label="at.amount"
                                                                icon="hashtag"
                                                                rules="required|min_value:0"
                                                                type="number"
                                                                :identifier="gkey"
                                                                :validate="trigger"
                                                                @input="handleGraduatedPriceInput"
                                                            />
                                                        </div>
                                                        <div>
                                                            <CurrencyInput
                                                                v-model="graduatedPrice.price"
                                                                :options="getComputedCurrencyOptions" names="price"
                                                                label="price"
                                                                icon="tag"
                                                                rules="required"
                                                                type="number"
                                                                :steps="0.01"
                                                                :validate="trigger"
                                                                :is-disabled="!item.overrideGraduatedPrices"
                                                            />
                                                        </div>

                                                          <div v-if="item.overrideGraduatedPrices"
                                                               class="uk-width-auto">
                                                            <LabelWithError class="uk-invisible" label="p"/>
                                                            <span
                                                                :uk-tooltip="'title:'+$t('x.delete', {x: $t('graduated.price')})"
                                                                class="cursor-pointer"
                                                                v-on:click="removeGraduatedPrice(item, gkey)">
                                                                <Icon icon="trash"/>
                                                            </span>
                                                        </div>

                                                    </div>



                                        </div>
                                    </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div class="uk-margin-medium-bottom"/>

                <div class="uk-grid uk-grid-small" uk-grid>

                    <div v-if="selected" class="uk-width-auto">
                        <div :uk-tooltip="$t('x.delete', {x: $t('article.list')})">
                            <Button :action="actionDeleteConfirm"
                                    has-action is-large
                                    mode="danger">
                                <Icon icon="trash"/>
                            </Button>
                        </div>
                    </div>
                    <div v-if="false && selected" class="uk-width-auto">
                         <div :uk-tooltip="$t('x.duplicate', {x: $t('article.list')})">
                            <Button :action="actionClone"
                                    has-action is-large
                                    mode="default">
                                <Icon icon="copy"/>
                            </Button>
                         </div>
                    </div>

                    <div class="uk-width-expand">
                        <Button
                            v-if="selected"
                            :action="actionUpdate"
                            :is-loading="loading"
                            :text="$t(selected ? (selectedClone ? 'x.duplicate' : 'x.save') : 'x.add', {x: $t('article.list')})"
                            has-action is-large
                            is-max
                            mode="primary"/>

                          <Button
                              v-else
                              :action="actionCreate"
                              :is-loading="createLoading"
                              :text="$t(selected ? (selectedClone ? 'x.duplicate' : 'x.save') : 'x.add', {x: $t('article.list')})"
                              has-action is-large
                              is-max
                              mode="primary"/>
                    </div>
                </div>



                <div v-if="createError" class="uk-margin-medium-bottom"/>
                <ErrorMessage :error="createError"/>
            </Modal>

            </div>

            <div class="uk-margin-medium-top"/>
            <Button v-if="computedList && computedList.length > 0"
                    :action="actionNew"
                    has-action mode="primary">
                {{ $t('x.add', {x: $t('article.list')}) }}
            </Button>

        </span>
    </span>
</template>


<script>

import Button from "@/components/generic/Button.vue";
import axios from "axios";
import Icon from "@/components/generic/Icon.vue";
import Translate from "@/components/Translate.vue";
import UIkit from "uikit";
import Modal from "@/components/generic/Modal.vue";
import ErrorMessage from "@/components/generic/ErrorMessage.vue";
import TextField from "@/components/generic/TextField.vue";
import LabelWithError from "@/components/generic/LabelWithError.vue";
import CurrencyInput from "@/components/generic/CurrencyInput.vue";
import SwitchButton from "@/components/generic/SwitchButton.vue";

export default {
    name: 'ArticleListForm',
    components: {
        SwitchButton,
        CurrencyInput,
        LabelWithError,
        TextField,
        ErrorMessage,
        Modal,
        Translate,
        Icon,
        Button,
    },
    props: {
        settings: Object,

        articleList: {
            id: String,
            name: String,
            articleRefs: [
                {
                    articleID: String,
                    price: Number,
                    graduatedPrices: [
                        {
                            amount: Number,
                            price: Number,
                        }
                    ],
                    minPrice: Number,
                }
            ],
            galleries: Array,
        },
        disabled: Boolean,
    },
    data() {
        return {
            hoveredArticleId: null,

            title: "article.lists",

            loading: false,
            success: false,
            error: null,

            loadingDelete: false,
            successDelete: false,
            errorDelete: null,

            errorArticle: null,

            modalIdDetails: "modal-article-list-details",
            modalIdDelete: "modal-article-list-delete",

            selected: null,
            selectedClone: null,

            trigger: 0,
            fieldsName: [
                {
                    value: "",
                    valid: true,

                    name: "name",
                    label: "name",
                    icon: "tag",
                    rules: "required|max:23",
                    type: "text",
                },
            ],
            articleRefs: [],
            selectedArticleID: "",

            createLoading: false,
            createSuccess: false,
            createError: null,
        }
    },
    computed: {
        filteredArticles() {
            let filtered = [];

            const articles = this.settings.articles;
            const articleRefs = this.articleRefs

            let usedArticleIDs = [];
            if (articleRefs) {
                for (let i = 0, j = articleRefs.length; i < j; i++) {
                    const articleRef = articleRefs[i];

                    usedArticleIDs.push(articleRef.articleID);
                }
            }

            for (let i = 0, j = articles.length; i < j; i++) {
                const article = articles[i];

                if (article.isTerminal) {
                    continue;
                }

                if (article.isHeightVariable) {
                    continue;
                }

                if (usedArticleIDs.includes(article.id)) {
                    continue;
                }

                filtered.push(article);
            }

            return filtered;
        },
        getComputedCurrencyOptions() {
            return {
                "currency": this.getInfos.currency,
                "currencyDisplay": "symbol",
                "valueRange": {
                    "min": 0
                },
                "precision": 2,
                "hideCurrencySymbolOnFocus": false,
                "hideGroupingSeparatorOnFocus": false,
                "hideNegligibleDecimalDigitsOnFocus": false,
                "autoDecimalDigits": false,
                "useGrouping": true,
                "accountingSign": false
            };
        },
        getComputedCurrencyOptionsGraduated() {
            return {
                "currency": this.getInfos.currency,
                "currencyDisplay": "symbol",
                "valueRange": {
                    "min": 0
                },
                "precision": {"min": 2, "max": 3},
                "hideCurrencySymbolOnFocus": false,
                "hideGroupingSeparatorOnFocus": false,
                "hideNegligibleDecimalDigitsOnFocus": false,
                "autoDecimalDigits": false,
                "useGrouping": true,
                "accountingSign": false
            };
        },
        computedList() {
            let list = this.settings.articleLists;

            if (!list) {
                return [];
            }

            // list = list.sort((a, b) => Number(b.galleries.length) - Number(a.galleries.length));

            return list;
        },
    },
    methods: {
        handleGraduatedPriceInput(value, fieldKey, identifier) {
            this.articleRefs[fieldKey].graduatedPrices[identifier].amount = value;
            this.$forceUpdate();
        },
        handleActive(val, itemKey) {
            this.articleRefs[itemKey].active = JSON.parse(JSON.stringify(val));
            this.$forceUpdate();
        },
        handleOverridePrice(val, itemKey) {
            if (!val) {
                this.articleRefs[itemKey].price = JSON.parse(JSON.stringify(this.articleRefs[itemKey].articlePrice));
            }

            this.articleRefs[itemKey].overridePrice = val;

            this.$forceUpdate();
        },
        handleOverrideGraduatedPrices(val, itemKey) {
            if (!val && this.articleRefs[itemKey].articleGraduatedPrices) {
                this.articleRefs[itemKey].graduatedPrices = JSON.parse(JSON.stringify(this.articleRefs[itemKey].articleGraduatedPrices));
            }

            this.articleRefs[itemKey].overrideGraduatedPrices = val;

            this.$forceUpdate();
        },
        handleOverrideActive(val, itemKey) {
            if (!val) {
                this.articleRefs[itemKey].active = JSON.parse(JSON.stringify(this.articleRefs[itemKey].articleActive));
            }

            this.articleRefs[itemKey].overrideActive = val;
            this.$forceUpdate();
        },
        removeGraduatedPrice(item, key) {
            item.graduatedPrices.splice(key, 1);
            this.$forceUpdate();
        },
        actionAddGraduatedPrices(item) {
            let newStruct = {
                amount: 0,
                price: 0,
            };

            item.graduatedPrices.push(newStruct);

            this.$forceUpdate();
        },
        getArticleByID(id) {
            for (let i = 0, j = this.settings.articles.length; i < j; i++) {
                const article = this.settings.articles[i];
                if (article.id === id) {
                    return article;
                }
            }

            return "";
        },
        renderArticleSelect(article) {
            let rendered = this.$t(article.type);

            if (article.type === 'orderControl') {
                rendered += ": " + article.ocId;

            } else if (article.type === 'noritsu') {
                rendered += ": " + article.noritsuID;

            } else if (article.type === 'mirage') {
                rendered += ": " + (article.mirageFile ? this.$t('with.x', {x: this.$t('file')}) : this.$t('without.x', {x: this.$t('file')}));
            }

            if (article.name) {
                rendered += " · " + article.name;
            }

            rendered += " · ";
            rendered += this.getNumberFormatted(article.width);

            if (!article.isHeightVariable) {
                rendered += " x ";
                rendered += this.getNumberFormatted(article.height);

            } /*else {
                rendered += " ";
                rendered += this.$t(this.settings.measurementUnit);
                rendered += " x ";
                rendered += this.$t('pictureLength');

            }

            if (article.maxLength) {
                rendered += " (";
                rendered += this.$t('max');
                rendered += " ";
                rendered += article.maxLength;
                rendered += " ";
                rendered += this.$t(this.settings.measurementUnit);
                rendered += ")";
            }
             */

            rendered += " · ";
            rendered += this.$t(article.paper);

            rendered += " · ";
            rendered += this.getNumberWithCurrency(article.price)

            if (article.isHeightVariable) {
                rendered += " ";
                rendered += this.$t('per.x', {x: this.$t(this.settings.measurementUnit)})

                if (article.minPrice) {
                    rendered += ", ";
                    rendered += this.$t('min') + ' ' + this.getNumberWithCurrency(article.minPrice)
                }
            }

            rendered += " · ";
            if (article.graduatedPrices && article.graduatedPrices.length > 0) {
                rendered += article.graduatedPrices.length;
                rendered += " ";

                if (article.graduatedPrices.length === 1) {
                    rendered += this.$t('graduated.price');

                } else {
                    rendered += this.$t('graduated.prices');

                }

            } else {
                rendered += "0 ";
                rendered += this.$t('graduated.prices');
            }

            return rendered;
        },
        removeArticleRef(key) {
            this.articleRefs.splice(key, 1);
        },
        addArticleRef() {
            if (this.selectedArticleID === "") {
                this.errorArticle = this.$t('x.required.message', {x: this.$t('article')});
                UIkit.scroll("#article-selector", {offset: 20}).scrollTo("#article-selector");
                return;
            }

            this.successCreateArticle = false;

            if (!this.articleRefs) {
                this.articleRefs = [];
            }

            const article = this.getArticleByID(this.selectedArticleID);

            let newStruct = {
                articleID: JSON.parse(JSON.stringify(this.selectedArticleID)),
                price: article.price,
                graduatedPrices: article.graduatedPrices,
                minPrice: article.minPrice,
                active: article.active,

                articlePrice: article.price ? JSON.parse(JSON.stringify(article.price)) : undefined,
                articleGraduatedPrices: article.graduatedPrices ? JSON.parse(JSON.stringify(article.graduatedPrices)) : undefined,
                articleMinPrice: article.minPrice ? JSON.parse(JSON.stringify(article.minPrice)) : undefined,
                articleActive: JSON.parse(JSON.stringify(article.active)),
            };

            this.articleRefs.push(newStruct);

            let self = this;
            setTimeout(function () {
                UIkit.scroll("#article-ref-" + self.selectedArticleID, {offset: 20}).scrollTo("#article-ref-" + self.selectedArticleID);
                self.selectedArticleID = "";
            }, 10);

            this.$forceUpdate();
        },
        setValidFieldsName(valid, fieldKey) {
            this.success = false;
            this.fieldsName[fieldKey].valid = valid
        },
        actionDeleteConfirm() {
            UIkit.modal("#" + this.modalIdDelete).show();
        },
        actionDelete(withNotification = true) {
            this.loadingDelete = true;

            let http = axios.create();
            http.delete(this.apiUrl + '/article-lists/' + this.selected.id).then(() => {
                this.$emit('updated');

                UIkit.modal("#" + this.modalIdDelete).hide();

                if (withNotification) {
                    UIkit.notification({
                        message: this.$t('article.list.deleted.successfully'),
                        status: 'info',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });
                }

                this.loadingDelete = false;

            }).catch(e => {
                this.checkGenericError(e);

                if (withNotification) {
                    UIkit.notification({
                        message: this.$t('unexpected.error'),
                        status: 'error',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });
                }

                this.loadingDelete = false;
            });
        },
        actionNew() {
            this.selected = null;
            this.selectedClone = null;

            this.fieldsName[0].value = "";
            this.fieldsName[0].valid = false;

            this.articleRefs = [];
            this.selectedArticleID = "";

            UIkit.modal("#" + this.modalIdDetails).show();
        },
        actionSelect(item) {
            if (item.articleRefs) {
                for (let i = 0, j = item.articleRefs.length; i < j; i++) {
                    const articleRef = item.articleRefs[i];
                    const article = this.getArticleByID(articleRef.articleID);

                    if (article.price !== undefined) {
                        articleRef.articlePrice = JSON.parse(JSON.stringify(article.price))
                    }
                    if (articleRef.price !== undefined) {
                        articleRef.overridePrice = true;
                    } else {
                        articleRef.price = JSON.parse(JSON.stringify(article.price));
                    }

                    if (article.minPrice !== undefined) {
                        articleRef.articleMinPrice = JSON.parse(JSON.stringify(article.minPrice));
                    }
                    if (articleRef.minPrice !== undefined) {
                        articleRef.overrideMinPrice = true;
                    } else if (article.isHeightVariable && article.minPrice) {
                        articleRef.minPrice = JSON.parse(JSON.stringify(article.minPrice));

                    }

                    if (article.graduatedPrices !== undefined) {
                        articleRef.articleGraduatedPrices = JSON.parse(JSON.stringify(article.graduatedPrices));
                    }
                    if (!articleRef.overrideGraduatedPrices) {
                        articleRef.graduatedPrices = JSON.parse(JSON.stringify(article.graduatedPrices));
                    }

                    if (articleRef.graduatedPrices === undefined) {
                        articleRef.graduatedPrices = [];
                    }


                    if (article.active !== undefined) {
                        articleRef.articleActive = JSON.parse(JSON.stringify(article.active))
                    }
                    if (articleRef.active !== undefined) {
                        articleRef.overrideActive = true;
                    } else {
                        articleRef.active = JSON.parse(JSON.stringify(article.active));
                    }
                }
            }

            this.selected = item;

            this.fieldsName[0].value = item.name;
            this.fieldsName[0].valid = true;

            this.articleRefs = item.articleRefs;

            let self = this;
            setTimeout(function () {
                self.trigger++;
                UIkit.modal("#" + self.modalIdDetails).show();
            }, 10);
        },
        actionClone(item) {
            this.selectedClone = item;
            this.actionSelect(item);
        },
        actionCreate() {
            this.trigger++;

            for (let i = 0, j = this.fieldsName.length; i < j; i++) {
                if (!this.fieldsName[i].valid) {
                    return;
                }
            }

            for (let i = 0, j = this.articleRefs.length; i < j; i++) {
                let articleRef = this.articleRefs[i];

                if (articleRef.price) {
                    articleRef.price = parseFloat(articleRef.price);
                } else {
                    articleRef.price = undefined;
                }

                if (articleRef.minPrice) {
                    articleRef.minPrice = parseFloat(articleRef.minPrice);
                } else {
                    articleRef.minPrice = undefined;
                }

                for (let k = 0, l = articleRef.graduatedPrices.length; k < l; k++) {
                    let graduatedPrice = articleRef.graduatedPrices[k];

                    graduatedPrice.price = parseFloat(graduatedPrice.price);
                }
            }

            this.createLoading = true;

            let http = axios.create();
            http.post(this.apiUrl + '/article-lists', {
                name: this.fieldsName[0].value,

            }).then((response) => {

                const parsedArticleRefs = this.getParsedArticleRefs();

                http.patch(this.apiUrl + '/article-lists/' + response.data.id + '/refs', {
                    articleRefs: parsedArticleRefs,

                }).then(() => {
                    this.createError = null;
                    this.createSuccess = true;
                    this.createLoading = false;

                    this.$emit('updated');

                    this.hoveredArticleId = response.data.id;
                    UIkit.notification({
                        message: this.$t('article.list.added.successfully'),
                        status: 'info',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });

                    UIkit.modal("#" + this.modalIdDetails).hide();

                }).catch(e => {
                    this.checkGenericError(e);

                    this.error = e;
                    this.success = false;
                    this.loading = false;

                });

            }).catch(e => {
                this.checkGenericError(e);

                this.error = e;
                this.success = false;
                this.loading = false;

            });
        },
        getParsedArticleRefs() {
            let parsedArticleRefs = [];

            for (let i = 0, j = this.articleRefs.length; i < j; i++) {
                const articleRef = this.articleRefs[i];

                let minPrice = undefined;
                if (articleRef.overrideMinPrice) {
                    minPrice = articleRef.minPrice;
                }

                let price = undefined;
                if (articleRef.overridePrice) {
                    price = articleRef.price;
                }

                let graduatedPrices = undefined;
                let overrideGraduatedPrices = false;
                if (articleRef.overrideGraduatedPrices) {
                    graduatedPrices = articleRef.graduatedPrices;
                    overrideGraduatedPrices = true;
                }

                let active = undefined;
                if (articleRef.overrideActive) {
                    active = articleRef.active;
                }

                parsedArticleRefs.push(
                    {
                        articleID: articleRef.articleID,
                        minPrice: minPrice,
                        price: price,
                        graduatedPrices: graduatedPrices,
                        active: active,
                        overrideGraduatedPrices: overrideGraduatedPrices,
                    }
                )
            }

            return parsedArticleRefs;
        },
        actionUpdate() {
            this.trigger++;

            for (let i = 0, j = this.fieldsName.length; i < j; i++) {
                if (!this.fieldsName[i].valid) {
                    return;
                }
            }

            for (let i = 0, j = this.articleRefs.length; i < j; i++) {
                let articleRef = this.articleRefs[i];

                if (articleRef.price) {
                    articleRef.price = parseFloat(articleRef.price);
                } else {
                    articleRef.price = undefined;
                }

                if (articleRef.minPrice) {
                    articleRef.minPrice = parseFloat(articleRef.minPrice);
                } else {
                    articleRef.minPrice = undefined;
                }

                for (let k = 0, l = articleRef.graduatedPrices.length; k < l; k++) {
                    let graduatedPrice = articleRef.graduatedPrices[k];

                    graduatedPrice.price = parseFloat(graduatedPrice.price);
                }
            }

            this.loading = true;

            let http = axios.create();
            http.patch(this.apiUrl + '/article-lists/' + this.selected.id + '/name', {
                name: this.fieldsName[0].value,

            }).then(() => {
                const parsedArticleRefs = this.getParsedArticleRefs();

                http.patch(this.apiUrl + '/article-lists/' + this.selected.id + '/refs', {
                    articleRefs: parsedArticleRefs,

                }).then(() => {
                    this.error = null;
                    this.success = true;
                    this.loading = false;

                    this.$emit('updated');

                    this.hoveredArticleId = this.selected.id;
                    UIkit.notification({
                        message: this.$t('x.changes.saved', {x: this.$t('article.list')}),
                        status: 'info',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });

                    UIkit.modal("#" + this.modalIdDetails).hide();

                }).catch(e => {
                    this.checkGenericError(e);

                    this.error = e;
                    this.success = false;
                    this.loading = false;

                });

            }).catch(e => {
                this.checkGenericError(e);

                this.error = e;
                this.success = false;
                this.loading = false;

            });
        },
    }
}

</script>


<style lang="scss" scoped>

.trash-distance {
    margin-top: 16px;
}

</style>
