<template>

    <span :class="'uk-label uk-label-'+type + generateClasses()" :uk-tooltip="tooltip? 'title:'+tooltip : ''">
        <IconText v-if="icon" :has-text="text !== undefined" :icon="icon">{{ text }}<slot/></IconText>
        <span v-else>{{ text }}<slot/></span>
    </span>

</template>


<script>

import IconText from "@/components/generic/IconText";

export default {
    name: 'Label',
    components: {IconText},
    props: {
        type: String,
        text: String,
        icon: String,
        isLarge: Boolean,
        tooltip: String,
        isRound: Boolean,
    },
    methods: {
        generateClasses() {
            let classes = '';

            if (this.isLarge) {
                classes += ' large';
            }

            if (this.isRound) {
                classes += ' round';
            }

            return classes;
        }
    }
}

</script>


<style lang="scss" scoped>

.uk-label-default {
    background-color: transparent;
    color: #333;
    border: 1px solid #e5e5e5;

}


.uk-label-default-alt2 {
    border: 2px solid #ddd !important;
    // color: #ddd !important;
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(29, 29, 31, 0.1) !important;


    &.hover,
    &:hover,
    &.active,
    &:active,
    &.focus,
    &:focus {
        border: 2px solid #ddd !important;
        color: #ddd !important;
    }
}


.uk-label-default-alt {
    background-color: #fff;
    color: #333;
    border: 1px solid #e5e5e5;
}


.uk-label-secondary {
    background: #222;
    color: #fff;
    border: 2px solid #222;
}

.large {
    font-size: 17px !important;
    padding: 5px 15px 5px 15px !important;
    font-weight: 500 !important;
}

.round {
    border-radius: 20px !important;
}

</style>
