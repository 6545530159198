<template>
    <span>

        <div class="uk-text-center">
            <div uk-spinner/>
            <div class="uk-margin-small-top">
                <Translate translation-key="terminal.connector.waiting"/>
            </div>
        </div>

        <div class="uk-margin-medium-top"/>

        <InfoBox mode="danger">
            <Translate translation-key="terminal.connector.not.running"/>
        </InfoBox>

    </span>
</template>

<script>

import Translate from "@/components/Translate.vue";
import InfoBox from "@/components/generic/InfoBox.vue";

export default {
    name: 'TerminalConnnectorNotRunnning',
    components: {InfoBox, Translate},
}

</script>

<style lang="scss" scoped>


</style>
