<template>
    <div class="site-struct single">

        <LoadingStruct is-dark :is-loading="false">
            <Card :title="$t('setup.title')" is-dark mode="default">

                <Description :text="$t('setup.description')"/>

                <span v-for="(inputField, key) in inputFields" :key="inputField.name">
                    <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                               :autofocus="inputField.autofocus" :field-key="key"
                               :hasPasswordMeter="inputField.hasPasswordMeter"
                               :icon="inputField.icon"
                               :is-optional="inputField.optional"
                               :label="inputField.label"
                               :name="inputField.name"
                               :on-enter="setup"
                               :rules="inputField.rules"
                               :type="inputField.type"
                               :validate="trigger"
                               @setValid="setValid"
                    />
                    <div v-if="key < inputFields.length-1" class="uk-margin-bottom"/>
                </span>

                <div v-if="!successSetup" class="uk-margin-medium-bottom"/>
                <Button v-if="!successSetup" :action="setup" :is-loading="loadingSetup" :text="$t('setup')" has-action
                        is-large is-max
                        mode="primary"/>

                <div v-if="errorSetup" class="uk-margin-medium-bottom"/>
                <ErrorMessage :custom-errors="[{code: 400, message: $t('setup.code.invalid')}]" :error="errorSetup"/>

                <div v-if="successSetup" class="uk-margin-medium-bottom"/>
                <SuccessMessage :message="$t('setup.success')" :success="successSetup"/>

                <div v-if="successSetup" class="uk-margin-bottom"/>
                <LinkObject v-if="successSetup" to="admin">
                    <Button :text="$t('admin.area')" is-large is-max mode="primary"/>
                </LinkObject>

            </Card>
        </LoadingStruct>

    </div>
</template>

<script>

import LoadingStruct from "@/views/LoadingStruct";
import Card from "@/components/generic/Card";
import Description from "@/components/Description";
import TextField from "@/components/generic/TextField";
import Button from "@/components/generic/Button";
import ErrorMessage from "@/components/generic/ErrorMessage";
import axios from "axios";
import VueCookie from "vue-cookie";
import SuccessMessage from "@/components/generic/SuccessMessage";
import LinkObject from "@/components/LinkObject";

export default {
    name: 'Setup',
    components: {
        LinkObject,
        SuccessMessage,
        ErrorMessage,
        Button,
        TextField,
        Description,
        Card,
        LoadingStruct,
    },
    data() {
        return {
            trigger: 0,
            inputFields: [
                {
                    value: "",
                    valid: false,

                    name: "email",
                    label: "emailAddress",
                    icon: "mail",
                    rules: "required|email",
                },
                {
                    value: "",
                    valid: false,

                    name: "new-password",
                    label: "password",
                    icon: "lock",
                    rules: "required|min:8|max:4096",
                    autocomplete: "new-password",
                    type: "password",
                    hasPasswordMeter: true,
                },
                {
                    value: "",
                    valid: false,

                    name: "setup-code",
                    label: "setup.code",
                    icon: "code",
                    rules: "required",
                },
            ],

            loadingSetup: false,
            errorSetup: null,
            successSetup: false,
        }
    },
    methods: {
        setValid(valid, fieldKey) {
            this.inputFields[fieldKey].valid = valid
        },
        setup() {
            this.trigger++;

            for (let i = 0, j = this.inputFields.length; i < j; i++) {
                if (!this.inputFields[i].valid) {
                    return;
                }
            }

            this.loadingSetup = true;
            let http = axios.create();
            http.post(this.apiUrl + '/setup', {
                "email": this.inputFields[0].value,
                "password": this.inputFields[1].value,
                "code": this.inputFields[2].value,
                "language": this.$i18n.locale,

            }).then(response => {
                this.$store.state.token = response.data.token;
                VueCookie.set('token', response.data.token, 60 * 60 * 24 * 7)
                axios.defaults.headers.common["token"] = response.data.token;
                this.receiveInfos();

                this.errorSetup = null;
                this.loadingSetup = false;
                this.successSetup = true;

            }).catch(e => {
                this.checkGenericError(e);
                this.errorSetup = e;
                this.loadingSetup = false;
                this.successSetup = false;

            });

        }
    }
}

</script>

<style lang="scss" scoped>

</style>
