<template>
    <span
        :uk-tooltip="disabled ? 'title:'+$t('x.module.disabled.hint', {x:$t('terminal')}) : ''"
        :class="disabled ? 'module-disabled-struct' : ''">

        <span :class="disabled ? 'module-disabled uk-height-1-1' : 'uk-height-1-1'">
            <Card is-dark
                  :title="$t(title)"
                  mode="default" id="terminal-register" class="uk-height-1-1">
                <Description v-if="description" :text="$t(description)"/>

                <div class="uk-margin-medium-bottom"/>


                 <InfoBox v-if="activatedInit" mode="success">
                     <Translate translation-key="terminal.mode.activated.message"/>
                 </InfoBox>
                <div v-if="activatedInit" class="uk-margin-medium-bottom"/>


                <Button v-if="activated"
                        :action="actionDelete"
                        :is-loading="loading"
                        :text="$t('x.deactivate', {x: $t(title)})"
                        has-action
                        is-large
                        is-max
                        mode="danger"
                />
                <Button
                    v-else
                    :action="actionCreate"
                    :is-loading="isAttemptedConnectorRegister && isTerminalConnectorRunning === null"
                    :text="$t('x.activate', {x: $t(title)})"
                    has-action
                    is-large
                    is-max
                    mode="primary"
                />


                <div v-if="error" class="uk-margin-medium-bottom"/>
                <InfoBox v-if="error"
                         mode="danger">
                     <Translate translation-key="terminal.connector.not.running"/>
                </InfoBox>


                 <div v-if="successCreated" class="uk-margin-medium-bottom"/>
                 <SuccessMessage :success="successCreated"
                                 :message="$t('terminal.mode.successfully.activated', {x: $t(title)})"/>

                <div v-if="successDeleted" class="uk-margin-medium-bottom"/>
                 <SuccessMessage :success="successDeleted"
                                 :message="$t('terminal.mode.successfully.deactivated', {x: $t(title)})"/>

            </Card>

        </span>
    </span>

</template>


<script>

import SuccessMessage from "@/components/generic/SuccessMessage.vue";
import Description from "@/components/Description.vue";
import Button from "@/components/generic/Button.vue";
import Card from "@/components/generic/Card.vue";
import Vue from "vue";
import InfoBox from "@/components/generic/InfoBox.vue";
import Translate from "@/components/Translate.vue";

export default {
    name: 'TerminalRegisterForm',
    components: {Translate, InfoBox, Card, Button, Description, SuccessMessage},
    props: {
        disabled: Boolean,
    },
    data() {
        return {
            title: "terminal.mode",
            description: "terminal.mode.message",

            loading: false,
            successDeleted: false,
            successCreated: false,
            error: null,

            activated: false,
            activatedInit: false,

            interval: 0,
        }
    },
    created() {
        const mode = Vue.localStorage.get('mode')
        if (mode === "terminal") {
            this.activated = true;
            this.activatedInit = true;
        }
    },
    methods: {
        actionCreate() {
            this.$store.state.attemptedConnectorRegister = true;

            let self = this;
            this.interval = setInterval(function () {
                if (self.isTerminalConnectorRunning !== null) {
                    clearInterval(self.interval);

                    if (self.isTerminalConnectorRunning === false) {
                        self.error = true;
                        return;
                    }

                    Vue.localStorage.set('mode', 'terminal');
                    self.activated = true;
                    self.successCreated = true;
                    self.successDeleted = false;
                    self.error = null;
                }
            }, 50);

        },
        actionDelete() {
            Vue.localStorage.remove('mode');
            this.activated = false;
            this.successCreated = false;
            this.successDeleted = true;
            this.activatedInit = false;
        },
    }
}

</script>


<style lang="scss" scoped>

</style>
