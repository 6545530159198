<template>
    <span class="link-item">

        <a v-if="isDisabled" :class="(isDark? 'dark' : '') + (isUnderlined? ' underlined' : '')">
            <slot/>
        </a>
        <a v-else :class="(isDark? 'dark' : '') + (isUnderlined? ' underlined' : '')"
           :uk-toggle="toggle">
            <slot/>
        </a>

    </span>
</template>

<script>
export default {
    name: 'ToggleItem',
    props: {
        toggle: String,
        isDark: Boolean,
        isUnderlined: Boolean,
        isDisabled: Boolean,
    },
}
</script>

<style lang="scss" scoped>

a {
    transition: color .25s linear;
    color: $site-color;
    text-decoration: none !important;

    &.active, &:hover, &.router-link-exact-active, &.router-link-active {
        color: $site-color-hover;
    }
}

</style>
