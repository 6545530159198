<template>
    <Card is-dark mode="default" class="uk-height-1-1">
        <LabelWithError
            :error="labelError"
            :label="$t(title) + '*'"
            is-label-large />

        <div class="uk-grid-small uk-child-width-1-1" uk-grid>
            <div v-for="method in value" :key="method.name">
                <span v-on:click="checkInputValidation">
                    <SwitchButton
                        icon="world"
                        v-model="method.value"
                        :label="$t(method.name)" />
                </span>
            </div>
        </div>

        <div class="uk-margin-medium-bottom" />
        <Button
            :action="actionUpdate"
            :is-loading="loading"
            :text="$t('x.save', {x: $t(title)})"
            has-action
            is-large
            is-max
            mode="primary" />

        <div v-if="error" class="uk-margin-medium-bottom" />
        <ErrorMessage :error="error" />

        <div v-if="success" class="uk-margin-medium-bottom" />
        <SuccessMessage
            :message="$t('x.changes.saved', {x: $t(title)})"
            :success="success" />
    </Card>
</template>

<script>
import SuccessMessage from '@/components/generic/SuccessMessage.vue';
import Button from '@/components/generic/Button.vue';
import Card from '@/components/generic/Card.vue';
import ErrorMessage from '@/components/generic/ErrorMessage.vue';
import axios from 'axios';
import LabelWithError from '@/components/generic/LabelWithError.vue';
import SwitchButton from '@/components/generic/SwitchButton.vue';

export default {
    name: 'ShippingCountriesForm',
    components: {
        SwitchButton,
        LabelWithError,
        ErrorMessage,
        Card,
        Button,
        SuccessMessage,
    },
    props: {
        shippingCountries: Array,
        allowedShippingCountries: Array,
    },
    mounted() {
        let value = [];
        for (let i = 0, j = this.allowedShippingCountries.length; i < j; i++) {
            value.push({
                name: this.allowedShippingCountries[i],
                value: this.shippingCountries.includes(
                    this.allowedShippingCountries[i]
                ),
            });
        }

        this.value = value;
    },
    data() {
        return {
            title: 'shipping.countries',
            labelError: null,

            loading: false,
            success: false,
            error: null,

            value: [],
        };
    },
    methods: {
        checkInputValidation() {
            let methods = [];
            for (let i = 0, j = this.value.length; i < j; i++) {
                if (this.value[i].value) {
                    methods.push(this.value[i].name);
                }
            }

            if (methods.length === 0) {
                this.labelError = this.$t('x.required.message', {
                    x: this.$t(this.title),
                });
                return;
            }

            this.labelError = null;
            this.success = false;
        },
        actionUpdate() {
            let methods = [];
            for (let i = 0, j = this.value.length; i < j; i++) {
                if (this.value[i].value) {
                    methods.push(this.value[i].name);
                }
            }

            if (methods.length === 0) {
                this.labelError = this.$t('x.required.message', {
                    x: this.$t(this.title),
                });
                return;
            }

            this.labelError = null;

            this.loading = true;

            let http = axios.create();
            http.put(this.apiUrl + '/shipping-countries', {
                shippingCountries: methods,
            })
                .then(() => {
                    this.error = null;
                    this.success = true;
                    this.loading = false;

                    this.$emit('updated');
                })
                .catch((e) => {
                    this.checkGenericError(e);

                    this.error = e;
                    this.success = false;
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped></style>
