<template>
    <span class="textarea">

        <LabelWithError is-slim v-if="label" :error="errors.first(name)" :label="$t(label)"
                        is-label-large="is-label-large"/>
        <div v-on:click="action">
            <textarea ref="ref"
                      :id="id"
                      v-validate="rules"
                      :autofocus="autofocus"
                      :class="classGenerator(name)"
                      :data-vv-as="$t(label)"
                      :disabled="isDisabled"
                      :name="name"
                      :rows="rows"
                      :uk-tooltip="tooltip? 'title:'+tooltip : ''"
                      :value="value"
                      v-on:input="updateValue($event.target.value)"
                      v-on:click="handleOnClick"
            />
        </div>

        <div v-if="showKeyboard"
             class="uk-margin-small-top uk-animation-fade">
            <SimpleKeyboard @onChange="updateValue" :keyboard-class="'simple-keyboard-'+name"/>
        </div>

    </span>
</template>


<script>

import LabelWithError from "@/components/generic/LabelWithError";
import SimpleKeyboard from "@/components/SimpleKeyboard.vue";
import UIkit from "uikit";

export default {
    name: 'TextArea',
    components: {SimpleKeyboard, LabelWithError},
    props: {
        validate: Number,
        value: String,
        icon: String,
        autofocus: {
            type: Boolean,
            default: false
        },
        rows: {
            type: Number,
            default: 3,
        },

        label: String,
        name: String,
        rules: String,
        fieldKey: Number,
        isDisabled: Boolean,
        isLabelLarge: Boolean,

        action: {
            type: Function,
            default: function () {

            }
        },
        tooltip: String,
        isLoading: Boolean,
        hasKeyboard: Boolean,
    },
    data() {
        return {
            showKeyboard: false,
            id: 'textfield-' + this.name,
        }
    },
    mounted() {
        if (this.hasKeyboard) {
            let self = this;
            window.addEventListener("close-keyboards", function () {
                self.showKeyboard = false;
            });
        }
    },
    watch: {
        validate() {
            this.validation();
        },
    },
    methods: {
        handleOnClick() {
            if (this.hasKeyboard) {
                let event = new CustomEvent("close-keyboards");
                this.globalDispatchEvent(event);

                this.showKeyboard = true;

                let self = this;
                setTimeout(function () {
                    UIkit.scroll("#" + self.id, {offset: 40}).scrollTo("#" + self.id);
                }, 10);
            }
        },
        validation() {
            this.$validator.validate().then(valid => {
                if (!valid && this.autofocus) {
                    this.$refs.ref.focus();
                }

                this.$emit('setValid', valid, this.fieldKey)
            });
        },
        updateValue(value) {
            this.$emit('input', value, this.fieldKey)
            this.validation();
        },
        classGenerator(name) {
            let classes = "uk-textarea";

            if (this.errors.first(name)) {
                classes += " danger";
            }

            if (this.action) {
                //classes += " cursor-pointer";
            }

            return classes;
        },
    }
}

</script>


<style lang="scss" scoped>

textarea {
    resize: vertical !important;

    &:hover, &:focus {
        border-color: $info-color !important;
    }

    &.dark {
        &:hover, &:focus {
            border-color: $theme-color-hover !important;
        }
    }

    &.danger {
        background: rgba(240, 80, 80, .15);
        border-color: $theme-background !important;
    }

    &.simple {
        border-radius: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }

    &:disabled {
        cursor: not-allowed;
    }

}

</style>
