import de from './de'
import ch from './de'
import at from './de'
import li from './de'
import en from './en'
import fr from './fr'
import it from './it'
import nl from './nl'
import fi from './fi'

export default {
    de,
    ch,
    at,
    li,
    en,
    it,
    fr,
    nl,
    fi,
}
