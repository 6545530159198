<template>
    <Card is-dark mode="default" class="uk-height-1-1">
        <LabelWithError :label="$t(title)+'*'"
                        is-label-large/>
        <select v-model="value" class="uk-select uk-input-styled"
                v-on:change="success= false; $forceUpdate()">
            <option v-for="option in measurementUnits" :key="option"
                    :value="option">
                {{ $t(option) }}
            </option>
        </select>

        <div class="uk-margin-medium-bottom"/>
        <Button :action="actionUpdate" :is-loading="loading"
                :text="$t('x.save', {x: $t(title)})"
                has-action
                is-large
                is-max
                mode="primary"/>

        <div v-if="error" class="uk-margin-medium-bottom"/>
        <ErrorMessage :error="error"/>

        <div v-if="success" class="uk-margin-medium-bottom"/>
        <SuccessMessage
            :message="$t('x.changes.saved', {x: $t(title)})"
            :success="success"/>
    </Card>
</template>


<script>

import SuccessMessage from "@/components/generic/SuccessMessage.vue";
import Button from "@/components/generic/Button.vue";
import Card from "@/components/generic/Card.vue";
import ErrorMessage from "@/components/generic/ErrorMessage.vue";
import axios from "axios";
import LabelWithError from "@/components/generic/LabelWithError.vue";

export default {
    name: 'MeasurementUnitForm',
    components: {LabelWithError, ErrorMessage, Card, Button, SuccessMessage},
    props: {
        measurementUnit: String,
        measurementUnits: Array,
    },
    mounted() {
        this.value = this.measurementUnit;
    },
    data() {
        return {
            title: "measurement.unit",

            loading: false,
            success: false,
            error: null,

            value: "",
        }
    },
    methods: {
        actionUpdate() {
            this.loading = true;

            let http = axios.create();
            http.put(this.apiUrl + '/measurement-unit', {
                measurementUnit: this.value,

            }).then(() => {
                this.error = null;
                this.success = true;
                this.loading = false;

                this.$emit('updated');

            }).catch(e => {
                this.checkGenericError(e);

                this.error = e;
                this.success = false;
                this.loading = false;

            });
        },
    }
}

</script>


<style lang="scss" scoped>

</style>
