<template>
    <div>

        <div uk-grid
             class="uk-grid-small uk-child-width-expand@l uk-child-width-1-3@m uk-child-width-1-3@s uk-child-width-1-2 uk-flex-center">
            <div v-for="(ad,key) in terminal.ads" :key="ad"
                 v-on:click="slideTo(key)"
                 class="cursor-pointer">
                <div class="uk-card uk-text-center uk-flex-middle">

                    <video v-if="isVideo(ad)"
                           class="uk-height-small"
                           muted
                           oncontextmenu="return false;"
                           playsinline
                           uk-video>
                        <source :src="apiUrl+'/terminal-ads/'+ad"
                                :type="'video/'+getVideoType(ad)">
                    </video>
                    <img v-else :src="apiUrl+'/terminal-ads/'+ad" class="uk-height-small" uk-img/>

                </div>
            </div>
        </div>

    </div>
</template>


<script>

import UIkit from "uikit";

export default {
    name: 'TerminalAdsHeader',
    props: {
        terminal: {
            screensavers: Array,
            screensaversText: String,
            sliderInterval: Number,
            sliderVideoAdjusted: Boolean,
            adsEnabled: Boolean,
            ads: Array,
        },
    },
    data() {
        return {
            slideshowId: "slideshow-terminal-ads",
        }
    },
    methods: {
        slideTo(index) {
            UIkit.slideshow("#" + this.slideshowId).show(index);
        },
    },
}

</script>


<style lang="scss" scoped>

</style>
