<template>
    <div :class="answered ? 'uk-animation-reverse uk-animation-fade uk-hidden' : (show ? '' : 'uk-hidden')">

        <hr v-if="isIOSInstaller" class="uk-margin-medium-top uk-margin-medium-bottom"/>

        <div v-if="isIOSInstaller" class="pwa-struct uk-text-center">
            <Card is-dark is-blank mode="default" is-without-body>
                <div class="uk-grid-small uk-width-auto uk-flex uk-flex-center uk-flex-middle" uk-grid>
                    <div>
                        <img src="/img/icons/android-chrome-512x512.png" class="logo uk-margin-small-right"
                             uk-img/>
                        <span class="name">{{ name }}</span>
                    </div>
                    <div>
                        App installieren mit
                        <Icon icon="push"/>
                        und "Zum Home-Bildschirm" wählen
                    </div>
                </div>
            </Card>
        </div>

    </div>
</template>


<script>

import Card from "@/components/generic/Card.vue";
import Icon from "@/components/generic/Icon.vue";

export default {
    name: 'PWAHintForm',
    components: {Icon, Card},
    props: {
        name: String,
    },
    data() {
        return {
            show: true,
            answered: false,
            cookieKey: 'pwa',

            isIOSInstaller: false,
        }
    },
    mounted() {
        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

        if (iOSSafari) {
            if (!navigator.standalone) {
                this.isIOSInstaller = true;
            } else {
                this.accept();
            }
        }

        // this.isIOSInstaller = true;
    },
    methods: {
        accept() {
            this.answered = true;
            this.$store.state.pwa = "accepted";
            this.$cookie.set(this.cookieKey, "accepted", {expires: this.cookieMaxExpiry});
        },
    }
}

</script>


<style lang="scss" scoped>

.logo {
    height: 50px;
}

.name {
    font-weight: 500;
    font-size: $site-font-size-large;
}

</style>
