<template>
    <div>
        <Modal :id="modalId"
               is-dark
               is-without-body
               is-without-close
               is-strict
               is-full>
            <div class="uk-position-relative uk-visible-toggle uk-modal-close"
                 tabindex="-1"
                 uk-height-viewport
                 :id="slideshowId"
                 :uk-slideshow="getSlideShowSettings()">

                <ul class="uk-slideshow-items" uk-height-viewport>
                    <li v-for="(screensaver, key) in terminal.ads" :key="key">
                        <div class="uk-position-cover">

                            <video v-if="screensaver && isVideo(screensaver)"
                                   muted
                                   oncontextmenu="return false;"
                                   playsinline
                                   uk-cover>
                                <source :src="apiUrl+'/terminal-ads/'+screensaver"
                                        :type="'video/'+getVideoType(screensaver)">
                            </video>
                            <img v-else-if="screensaver" :src="apiUrl+'/terminal-ads/'+screensaver"
                                 class="uk-background-cover uk-animation-kenburns uk-animation-reverse" uk-cover>

                        </div>
                    </li>
                </ul>


                <div class="uk-position-center">


                    <div class="uk-margin-xlarge-bottom uk-light">

                        <Card mode="blur" class="uk-text-center">
                            <ModalTitle :title="$t('order.created.title')"/>

                            <Description>
                                <Translate class="print-description"
                                           :translation-key="parsedPictures.length===1 ? 'print.message.singular' : 'print.message.plural'"/>
                            </Description>

                            <div class="uk-margin-small-bottom">
                                <div class="uk-position-relative uk-visible-toggle uk-height-small"
                                     tabindex="-1"
                                     :id="slideshowIdPrinting"
                                     uk-slideshow="draggable: false; ratio: false;">

                                    <ul class="uk-slideshow-items uk-height-small">
                                        <li v-for="picture in parsedPictures" :key="picture.originKey">
                                            <img :src="picture.thumbnailCroppedUrl" class="uk-height-small"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <Translate class="print-status" translation-key="print.status"
                                       :parameter="{current: (status+1), total: parsedPictures.length}"
                            />
                        </Card>


                    </div>

                </div>

                <div class="uk-position-bottom-center">
                    <div class="uk-flex uk-flex-center uk-margin-bottom powered">
                        {{ terminal.screensaversText }}
                    </div>
                </div>

            </div>
        </Modal>
    </div>
</template>


<script>

import Modal from "@/components/generic/Modal.vue";
import UIkit from "uikit";
import ModalTitle from "@/components/generic/ModalTitle.vue";
import Card from "@/components/generic/Card.vue";
import Description from "@/components/Description.vue";
import Translate from "@/components/Translate.vue";

export default {
    name: 'TerminalAdsScreensaver',
    components: {Translate, Description, Card, ModalTitle, Modal},
    props: {
        pictures: Array,

        terminal: {
            screensavers: Array,
            screensaversText: String,
            sliderInterval: Number,
            sliderVideoAdjusted: Boolean,
            hasAds: Boolean,
            ads: Array,
        },
    },
    data() {
        return {
            status: 0,
            interval: 0,

            modalId: "modal-terminal-ads-screensaver",
            slideshowId: "slideshow-terminal-ads-screensavers",

            slideshowIdPrinting: "slideshow-printing",

            parsedPictures: [],
        }
    },
    created() {
        let parsed = [];

        let pictures = this.pictures;
        for (let i = 0, j = pictures.length; i < j; i++) {
            const picture = pictures[i];

            for (let k = 0, l = picture.amount; k < l; k++) {
                parsed.push(picture);
            }
        }

        this.parsedPictures = parsed;
    },
    mounted() {
        let self = this;
        this.interval = setInterval(function () {
            self.updateStatus();
        }, 5000);

        if (this.terminal.sliderVideoAdjusted) {
            const slideshow = UIkit.slideshow("#" + this.slideshowId);
            const slideshowElement = document.getElementById(this.slideshowId);
            slideshowElement.addEventListener('itemshow', (event) => {
                const element = event.target.children[0].children[0];
                const currentIndex = event.detail[0].index

                let nextIndex = currentIndex + 1;
                if (this.terminal.screensavers.length > nextIndex) {
                    nextIndex = 0;
                }

                if (element && element.tagName === 'VIDEO') {
                    slideshow.stopAutoplay();

                    element.addEventListener('ended', () => {
                        slideshow.show(nextIndex);
                        slideshow.startAutoplay();
                    });
                }
            });
        }

    },
    methods: {
        updateStatus() {
            const nextStatus = this.status + 1;
            if (nextStatus >= this.parsedPictures.length) {
                this.$emit('ended');
                return;
            }

            UIkit.slideshow("#" + this.slideshowIdPrinting).show(nextStatus);

            this.status = nextStatus;
        },
        getSlideShowSettings() {
            let interval = this.terminal.sliderInterval * 1000;

            return "ratio: false; autoplay: true; autoplay-interval: " + interval + "; draggable: false; animation: push";
        },
    }
}

</script>


<style lang="scss" scoped>

.start-struct {
    min-width: 300px;
    width: 25vw;
}

.uk-slideshow-items {
    opacity: 0.8;
}

.powered {
    font-size: $site-font-size-large;
    color: $site-color;
}

.print-description {
    font-size: 20px;
}

.print-status {
    font-size: 18px;
}

</style>
