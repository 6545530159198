<template>
    <span>

        <Icon :class="hasText ? 'distance' : ''" :icon="icon" :ratio="ratio"/><slot/>

    </span>
</template>

<script>
import Icon from "./Icon";

export default {
    name: 'IconText',
    components: {Icon},
    props: {
        icon: String,
        ratio: Number,
        hasText: {
            type: Boolean,
            default: true,
        }
    },
}
</script>

<style lang="scss" scoped>

.distance {
    margin-right: 8px;
}

</style>
