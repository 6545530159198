<template>
    <div>
        <div class="blank-struct" />

        <LoadingStruct
            class=" transfer-struct"
            is-dark
            :is-loading="loading"
            :error="error">
            <countdown
                v-if="transfer && transfer.expiresAt"
                class="uk-hidden"
                :end-time="new Date(transfer.expiresAt).getTime()"
                @finish="reload" />

            <Card
                is-round
                v-if="error && error.message.includes('404')"
                :title="$t('transfer.does.not.exist')"
                is-dark
                class="uk-text-left"
                mode="default"
                is-centered>
                <InfoBox class="uk-text-left" mode="danger">
                    <Translate
                        translation-key="transfer.does.not.exist.message" />
                </InfoBox>
            </Card>

            <Card
                v-else
                is-round
                :title="transfer.name"
                is-dark
                mode="default"
                is-centered>
                <a
                    :href="
                        apiUrl +
                        '/transfers/' +
                        transferToken +
                        '/' +
                        transfer.name
                    "
                  >
                    <Button
                        is-round
                        is-large
                        is-min-width
                        mode="success"
                        :is-loading="loadingDownload">
                        <Translate translation-key="download" />
                    </Button>
                </a>

                <div class="uk-margin-small-top">
                    {{ getParsedBytes(transfer.sizeInBytes) }}
                </div>

                <div
                    class="uk-margin-medium-top uk-text-left word-struct"
                    v-if="transfer.message">
                    <InfoBox borderless slim is-round mode="blank">
                        {{ transfer.message }}
                    </InfoBox>
                </div>

                <div class="uk-margin-medium-top word-struct">
                    <InfoBox
                        borderless
                        mode="blank"
                        slim
                        is-round>
                        <span
                            :uk-tooltip="
                                'title:' +
                                $t('On') +
                                ' ' +
                                getFormattedDateTimeByDateString(
                                    transfer.expiresAt
                                ) +
                                ' ' +
                                $t('clock')
                            ">
                            <Translate translation-key="expires.at" />
                            {{
                                getDaysLeftByDateString(
                                    transfer.expiresAt,
                                    false
                                )
                            }}
                        </span>
                    </InfoBox>
                </div>
            </Card>
        </LoadingStruct>
    </div>
</template>

<script>
import LoadingStruct from '@/views/LoadingStruct';
import Card from '@/components/generic/Card';
import axios from 'axios';
import Translate from '../components/Translate.vue';
import Button from '../components/generic/Button.vue';
import InfoBox from '../components/generic/InfoBox.vue';

export default {
    name: 'Transfer',
    components: {
        InfoBox,
        Button,
        Translate,
        Card,
        LoadingStruct,
    },
    data() {
        return {
            loading: true,
            error: null,

            transfer: {
                expiresAt: '',
                name: '',
                sizeInBytes: 0,
            },

            loadingDownload: false,

            transferToken: '',
        };
    },
    created() {
        this.getTransfer();
        this.transferToken = this.$route.params.transfer;
    },
    methods: {
        getTransfer() {
            const transferToken = this.$route.params.transfer;

            let http = axios.create();
            http.get(this.apiUrl + '/transfers/' + transferToken)
                .then((response) => {
                    this.transfer = response.data;

                    this.error = null;
                    this.loading = false;
                })
                .catch((e) => {
                    this.checkGenericError(e);
                    this.error = e;
                    this.loading = false;
                });
        },
        actionDownload() {
            event.preventDefault();
            const transferToken = this.$route.params.transfer;

            let self = this;
            self.loadingDownload = true;

            let request = new XMLHttpRequest();
            request.open(
                'GET',
                this.apiUrl +
                    '/transfers/' +
                    transferToken +
                    '/' +
                    self.transfer.name,
                true
            );
            request.responseType = 'blob';
            request.onload = function () {
                let blob = request.response;
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = self.transfer.name;
                link.click();

                self.loadingDownload = false;
            };

            request.send();
        },
    },
};
</script>

<style lang="scss" scoped>
.blank-struct {
   // height: 50vh;
}

.word-struct {
    word-wrap: break-word;
}

.transfer-struct {
    max-width: 500px;
}
</style>
