<template>
    <Card :title="$t(title)" is-dark mode="default" id="logo-form" class="uk-height-1-1">

        <Modal id="modal-logo" is-without-body is-close-outside>
            <div class="uk-text-center logo-form-struct">
                <img v-if="input" class="logo-form-item" :src="getLocalFileUrl(input)" uk-img/>
                <img v-else-if="logo" class="logo-form-item" :src="apiUrl+'/logos/'+logo" uk-img/>
            </div>
        </Modal>

        <LabelWithError :error="inputError" :label="$t('picture')" is-dark is-optional/>


        <div class="uk-position-relative logo-form-struct uk-text-center" id="logo-upload">
            <img v-if="input" class="logo-form-item" :src="getLocalFileUrl(input)" uk-img/>
            <img v-else-if="logo" class="logo-form-item" :src="apiUrl+'/logos/'+logo" uk-img/>
            <div v-else class="logo uk-light uk-flex uk-flex-middle uk-flex-center">
                {{ $t('no.x', {x: $t(title)}) }}
            </div>
        </div>

        <div class="uk-card uk-card-body uk-padding-xsmall control secondary-with-border">
            <div class="uk-flex-center uk-flex" uk-grid>

                <div>
                            <span v-on:click="triggerInputDialog()">
                                <Icon :ratio="1.3"
                                      :uk-tooltip="'title:'+$t(input || logo ? 'replace' : 'add')"
                                      class="cursor-pointer action-icon"
                                      icon="cloud-upload"/>
                            </span>

                    <input id="logo"
                           ref="logo"
                           :accept="'.'+fileExtensions.join(', .')"
                           type="file"
                           class="uk-hidden"
                           v-on:click="$refs.logo.value=null"
                           v-on:change="handleChange()"
                    />
                </div>

                <div v-if="input || logo">
                    <ToggleItem toggle="target: #modal-logo">
                        <Icon :ratio="1.3" :uk-tooltip="'title:'+$t('show')"
                              class="cursor-pointer action-icon"
                              icon="search"/>
                    </ToggleItem>
                </div>

                <div v-if="input || logo">
                            <span v-on:click="handleDelete()">
                                <Icon :ratio="1.5"
                                      :uk-tooltip="'title:'+$t('delete')"
                                      class="cursor-pointer action-icon"
                                      icon="trash"/>
                            </span>
                </div>

            </div>
        </div>


        <div class="uk-margin-medium-bottom"/>
        <LabelWithError :label="$t('position')+'*'"/>
        <div class="uk-margin-bottom"/>

        <ul class="uk-nav uk-navbar-dropdown-nav admin-struct">
            <li v-for="(allowedLogoPosition, key) in allowedLogoPositions"
                :key="allowedLogoPosition">

                <label class="cursor-pointer">
                    <div uk-grid>
                        <div class="uk-width-expand uk-flex uk-flex-middle">
                            {{ $t(capitalize(allowedLogoPosition)) }}
                        </div>
                        <div class="uk-flex uk-flex-middle">
                            <input v-model="logoPosition"
                                   v-on:change="success= false"
                                   :name="'logo-positions'"
                                   :value="allowedLogoPosition"
                                   class="uk-radio" type="radio">
                        </div>
                    </div>
                </label>

                <hr v-if="key !== allowedLogoPositions.length-1"/>
            </li>
        </ul>


        <div class="uk-margin-medium-bottom"/>
        <Button :action="actionUpdate"
                :is-loading="loading"
                :text="$t('x.save', {x: $t(title)})"
                has-action
                is-large
                is-max
                mode="primary"
        />

        <div v-if="error" class="uk-margin-medium-bottom"/>
        <ErrorMessage :error="error"/>

        <div v-if="success" class="uk-margin-medium-bottom"/>
        <SuccessMessage :success="success" :message="$t('x.changes.saved', {x: $t(title)})"/>

    </Card>
</template>


<script>

import SuccessMessage from "@/components/generic/SuccessMessage.vue";
import Button from "@/components/generic/Button.vue";
import Card from "@/components/generic/Card.vue";
import ErrorMessage from "@/components/generic/ErrorMessage.vue";
import axios from "axios";
import Icon from "@/components/generic/Icon.vue";
import Modal from "@/components/generic/Modal.vue";
import ToggleItem from "@/components/generic/ToggleItem.vue";
import LabelWithError from "@/components/generic/LabelWithError.vue";

export default {
    name: 'LogoForm',
    components: {LabelWithError, ToggleItem, Modal, Icon, ErrorMessage, Card, Button, SuccessMessage},
    props: {
        data: String,
        position: String,

        allowedExtensions: Array,
        allowedSize: Number,
        allowedLogoPositions: Array,
    },
    created() {
        this.logo = this.data;
        this.logoPosition = this.position;
    },
    mounted() {
        // window.addEventListener('dragover', this.dragoverListener, false);
        // window.addEventListener('drop', this.dropListener, false);
    },
    data() {
        return {
            title: "logo",

            loading: false,
            success: false,
            error: null,

            input: null,
            inputError: null,
            logo: null,
            logoPosition: "",
        }
    },
    computed: {
        fileExtensions() {
            const extensions = this.allowedExtensions;

            const lower = extensions.map(element => {
                return element.toLowerCase();
            });

            return [...new Set(lower)];
        },
    },
    methods: {
        dragoverListener(e) {
            e.preventDefault();

            let element = document.getElementById('logo-upload');
            if (element) {
                element.classList.add('dragover');
            }
        },
        dropListener(e) {
            e.preventDefault();

            let element = document.getElementById('logo-upload');
            if (element) {
                element.classList.remove('dragover');
            }

            let files = e.dataTransfer.files;

            this.handleFileInput(files[0]);
        },
        triggerInputDialog() {
            document.getElementById('logo').click();
        },
        handleDelete() {
            this.success = false;
            this.logo = null;
            this.input = null;
        },
        handleChange() {
            let file = this.$refs['logo'].files[0];
            if (!file) {
                return;
            }

            this.handleFileInput(file);
        },
        handleFileInput(file) {
            let fileExtension = file.name.split('.').pop();
            fileExtension = fileExtension.toLowerCase();
            if (!this.fileExtensions.includes(fileExtension)) {
                this.inputError = this.$t("files.type.error", {
                    name: this.$t(this.title),
                    types: this.getFormattedTypesString(this.fileExtensions)
                });

                return;
            }

            if (file.size > (this.allowedSize * 1024 * 1024)) {
                this.inputError = this.$t("files.size.error", {
                    name: this.$t(this.title),
                    mb: this.allowedSize
                });
                return;
            }

            this.inputError = null;
            this.error = null;
            this.success = false;
            this.input = file;
        },
        actionUpdate() {
            let formData = new FormData();
            formData.append("file", this.input);

            const config = {
                headers: {
                    'deleteFile': this.logo === null ? 'true' : 'false',
                    'position': this.logoPosition,
                },
            }

            this.loading = true;

            let http = axios.create();
            http.put(this.apiUrl + '/logo', formData, config)
                .then(() => {
                    this.error = null;
                    this.success = true;
                    this.loading = false;

                    this.$emit('updated');

                })
                .catch(e => {
                    this.checkGenericError(e);

                    this.error = e;
                    this.success = false;
                    this.loading = false;

                });
        },
    }
}

</script>


<style lang="scss" scoped>

.logo-form-item {
    max-width: 256px;
    max-height: 256px;

    width: 100%;
    height: 100%;
}

.logo-form-struct {
    padding: 15px;
    background-color: rgba(34, 34, 34, 0.72);
    border: 1px rgba(34, 34, 34, 0.72) solid;
    backdrop-filter: saturate(180%) blur(20px);
    transition: background-color .25s linear;
}

.dragover {
    background-color: rgba(34, 34, 34, 0.4);
    border: 1px rgba(34, 34, 34, 0.72) dashed;
}

</style>
