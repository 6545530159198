<template>
    <span>

    <router-link v-if="!disabled" :to="'/' + $i18n.locale + '/' + to">
        <slot/>
    </router-link>
    <slot v-else/>

    </span>
</template>

<script>
export default {
    name: 'LinkObject',
    props: {
        to: String,
        disabled: Boolean,
    },
}
</script>

<style lang="scss" scoped>

a {
    transition: color .25s linear;
    color: $site-color;
    text-decoration: none !important;

    &:hover {
        color: $site-color-hover;
    }
}

</style>
