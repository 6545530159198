<template>
    <div>

        <div class="uk-card uk-card-default uk-card-hover cursor-pointer">

            <div class="uk-inline uk-transition-toggle uk-width-1-1">
                <div class="uk-height-medium uk-background-cover"
                     :data-src="folder.thumbnail ? apiUrl+'/galleries/'+galleryID+(isRoot ? '' : '/folders/'+folder.name)+'/thumbnail/'+folder.thumbnail+'?token='+token : require('../assets/default_thumbnail.jpg')"
                     uk-img>

                    <div class="uk-position-top-right uk-padding-small uk-text-center uk-overlay-hover">
                        <div>
                            <Label type="default-alt" icon="image" class="cursor-pointer uk-width-1-1">
                                {{ folder.stats.pictures }}

                                <Translate v-if=" folder.stats.pictures === 1" translation-key="picture"/>
                                <Translate v-else translation-key="pictures"/>
                            </Label>
                        </div>

                    </div>

                </div>
            </div>

            <div class="uk-card uk-card-body uk-padding-xsmall control title-struct uk-text-center">
                <div class="gallery-title">{{ folder.name }}</div>
            </div>

        </div>


    </div>
</template>


<script>

import Translate from "@/components/Translate.vue";
import Label from "@/components/Label.vue";

export default {
    name: 'GalleryFolderItem',
    components: {Label, Translate},
    props: {
        galleryID: String,
        isRoot: Boolean,

        token: String,

        folder: {
            name: String,
            thumbnail: String,
            stats: {
                pictures: Number,
            },
        }
    },
}

</script>

<style lang="scss" scoped>

.gallery-title {
    font-size: 24px;
    color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.generated-title {
    text-transform: uppercase;
    font-size: 64px;
    font-weight: 500;
}

.control {
    background-color: $theme-background;
}

</style>
