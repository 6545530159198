var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uk-card uk-card-default uk-card-hover"},[(_vm.item.isDir)?_c('div',{staticClass:"uk-height-small uk-text-center folder-struct uk-padding-small uk-flex-middle uk-transition-toggle",attrs:{"uk-tooltip":'title:' + _vm.item.name}},[_c('Icon',{attrs:{"icon":"folder","ratio":2.5}}),_c('div',{staticClass:"action-name uk-margin-small-top folder-name"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('div',{staticClass:"uk-transition-fade uk-overlay uk-overlay-primary uk-position-cover"},[_c('div',{staticClass:"uk-flex uk-flex-middle uk-flex-center uk-height-1-1"},[_c('span',{on:{"click":function($event){return _vm.actionOpenFolder()}}},[_c('Icon',{staticClass:"cursor-pointer",attrs:{"icon":"folder","uk-tooltip":'title:' + _vm.$t('folder.open'),"ratio":1.5}})],1),_c('span',{staticClass:"uk-margin-small-left",on:{"click":function($event){return _vm.actionChangeThumbnail()}}},[_c('Icon',{staticClass:"cursor-pointer",attrs:{"icon":"image","uk-tooltip":'title:' + _vm.$t('thumbnail.change'),"ratio":1.5}})],1),_c('span',{staticClass:"uk-margin-small-left",on:{"click":function($event){return _vm.actionDeleteFolder()}}},[_c('Icon',{staticClass:"cursor-pointer",attrs:{"icon":"trash","uk-tooltip":'title:' + _vm.$t('delete'),"ratio":1.5}})],1)])])],1):_c('div',[_c('div',{staticClass:"uk-cover-container uk-transition-toggle uk-height-small",attrs:{"uk-tooltip":'title:' + _vm.item.name}},[_c('div',{staticClass:"uk-flex uk-flex-center uk-flex-middle image-item"},[_c('img',{staticClass:"image-item-struct",attrs:{"src":_vm.apiUrl +
                        '/manage-galleries/' +
                        _vm.galleryId +
                        (_vm.folder ? '/folders/' + _vm.folder.name : '') +
                        '/file-thumbnails/' +
                        _vm.item.name +
                        '?token=' +
                        _vm.getToken,"alt":_vm.item.name,"uk-img":""}})]),_c('div',{staticClass:"uk-transition-fade uk-overlay uk-overlay-primary uk-position-cover"},[_c('div',{staticClass:"uk-flex uk-flex-middle uk-flex-center uk-height-1-1"},[_c('span',{on:{"click":function($event){return _vm.actionDeleteFile()}}},[_c('Icon',{staticClass:"cursor-pointer",attrs:{"icon":"trash","uk-tooltip":'title:' + _vm.$t('delete'),"ratio":1.5}})],1)])])]),_c('div',{staticClass:"name uk-text-center",attrs:{"uk-tooltip":'title:' + _vm.item.name}},[_vm._v(" "+_vm._s(_vm.item.name)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }