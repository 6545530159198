<template>
    <span>
        <LoadingStruct is-dark :is-loading="loading">

            <Galleries v-if="getMode ==='gallery'"/>
            <Main v-else/>

            <ErrorMessage :error="error"/>

        </LoadingStruct>
    </span>
</template>


<script>

import Main from "@/views/Main.vue";
import axios from "axios";
import Galleries from "@/views/Galleries.vue";
import LoadingStruct from "@/views/LoadingStruct.vue";
import ErrorMessage from "@/components/generic/ErrorMessage.vue";

export default {
    name: 'Start',
    components: {ErrorMessage, LoadingStruct, Galleries, Main},
    data() {
        return {
            loading: true,
            mode: "upload",
            error: null,
        }
    },
    created() {
        this.getNode();
    },
    methods: {
        getNode() {
            let http = axios.create();
            http.get(this.apiUrl + '/mode').then(response => {
                this.$store.state.mode = response.data.mode;
                this.error = null;
                this.loading = false;

            }).catch(e => {
                this.checkGenericError(e);
                this.error = e;
                this.loading = false;

            });
        },
    }
}

</script>


<style lang="scss" scoped>

</style>
