<template>
    <img
        :alt="alt"
        :data-src="src"
        :style="'background-color:'+backgroundColor"
        class="lozad"
    />
</template>

<script>
import lozad from 'lozad';

export default {
    name: 'ImageEnhanced',
    props: {
        src: String,
        alt: String,
        backgroundColor: String,
    },
    mounted() {
        const observer = lozad(this.$el);
        observer.observe();
    }
}

</script>

<style lang="scss" scoped>

</style>
