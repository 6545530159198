<template>
    <div
        :class="'uk-height-1-1 card-select'+(isSelected ? ' selected' : '')+(isMuted ? ' muted' : '')+(isDisabled ? ' disabled' : '')+(isError ? ' error' : '')+(withLineHeight ? ' lineheight' : '')">
        <div class="struct">
            <Icon v-if="false" class="uk-margin-small-right" icon="check"/>
            <slot/>
        </div>
    </div>
</template>

<script>

import Icon from "@/components/generic/Icon";

export default {
    name: 'LabelSelect',
    components: {Icon},
    props: {
        isSelected: Boolean,
        isError: Boolean,
        withLineHeight: Boolean,
        isDisabled: Boolean,
        isMuted: Boolean,
    },
}

</script>

<style lang="scss" scoped>

.struct {
    padding: 5px 5px !important;
}

.card-select {
    margin-top: 2px !important;
    border: 1px solid #e5e5e5;
    width: 100%;
    background: none !important;
    color: $site-background !important;
    cursor: pointer;

    &.disabled {
        border: 1px solid #e5e5e5 !important;
        cursor: not-allowed !important;
    }

    &.muted {
        cursor: initial !important;
    }

    &.selected {
        border-color: $info-color !important;
        //border-width: 2px;
    }

    &:hover {
        border-color: #949393;
    }


    &.error {
        background: rgba(240, 80, 80, .15) !important;
        border-color: $theme-background !important;
    }

    &.lineheight {
        line-height: 50px;
    }
}

</style>
