<template>
    <div>

        <div :class="'label'+(isLabelLarge ? ' large' : '')+(isBold ? ' bold' : '')+(isDark ? ' dark' : '')+(isSlim ? ' slim' : '')">
            <div v-if="error">
                <div class="input-error"><span v-html="removeSentenceEndingChar()"/>.</div>
            </div>
            <div v-else>
                <span v-html="label"/> <span v-if="isOptional">({{ $t('optional') }})</span>
                <slot/>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: 'LabelWithError',
    props: {
        label: String,
        error: String,
        isLabelLarge: Boolean,
        isDark: Boolean,
        isOptional: Boolean,
        isBold: Boolean,
        isSlim: Boolean,
    },
    methods: {
        removeSentenceEndingChar() {
            if (this.label.endsWith(".")) {
                return this.error.replace(".", "");
            }

            return this.error;
        },
    }
}
</script>

<style lang="scss" scoped>

.label {
    margin-bottom: 8px;
    font-size: $site-font-size-small;

    &.bold {
        font-weight: 500;
    }

    &.large {
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 30px;
        // color: $site-color-hover;

        &.dark {
            color: $site-background-hover;
        }

        &.slim {
            margin-bottom: 10px;
        }
    }
}


.input-error {
    color: $danger-color;
}

</style>
